import pageStrings from '@/../../shared/messages.json';

export const LocaleToLanguage: { [key: string]: string } = {
  "af": 'afrikaans',
  "ar": 'arabic',
  "hy": 'armenian',
  "az": 'azerbaijani',
  "be": 'belarusian',
  "bs": 'bosnian',
  "bg": 'bulgarian',
  "ca": 'catalan',
  "yue": 'cantonese',
  "zh": 'mandarin_chinese',
  "hr": 'croatian',
  "cs": 'czech',
  "da": 'danish',
  "nl": 'dutch',
  "en": 'english',
  "et": 'estonian',
  "fi": 'finnish',
  "fr": 'french',
  "gl": 'galician',
  "de": 'german',
  "el": 'greek',
  "gu": 'gujarati',
  "he": 'hebrew',
  "hi": 'hindi',
  "hu": 'hungarian',
  "is": 'icelandic',
  "id": 'indonesian',
  "it": 'italian',
  "ja": 'japanese',
  "kn": 'kannada',
  "kk": 'kazakh',
  "ko": 'korean',
  "lv": 'latvian',
  "lt": 'lithuanian',
  "mk": 'macedonian',
  "ms": 'malay',
  "mr": 'marathi',
  "mi": 'maori',
  "ne": 'nepali',
  "no": 'norwegian',
  "fa": 'persian',
  "pl": 'polish',
  "pt": 'portuguese',
  "ro": 'romanian',
  "ru": 'russian',
  "sr": 'serbian',
  "sk": 'slovak',
  "sl": 'slovenian',
  "es": 'spanish',
  "sw": 'swahili',
  "sv": 'swedish',
  "tl": 'tagalog',
  "ta": 'tamil',
  "th": 'thai',
  "tr": 'turkish',
  "uk": 'ukrainian',
  "ur": 'urdu',
  "vi": 'vietnamese',
  "cy": 'welsh',
};


export function getMessagesFromLanguage(language: string): { [key: string]: string } {

  const messages: { [key: string]: string } = {};
  // First, add all English messages as default
  for (const key in pageStrings) {
    if (pageStrings.hasOwnProperty(key)) {
      // @ts-ignore
      const translations = pageStrings[key] as Record<string, string>;
      if (language in translations) {
        messages[key] = translations[language];
      } else {
        messages[key] = translations.english;
      }
    }
  }

  return messages;
}

export function getMessagesFromLocale(locale: string): { [key: string]: string } {
  return getMessagesFromLanguage(LocaleToLanguage[locale]);
}
