<script setup lang="ts">
import { cn } from '@/practice/util'
import {
  SwitchRoot,
  type SwitchRootEmits,
  type SwitchRootProps,
  SwitchThumb,
  useForwardPropsEmits,
} from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'
import { PhPhone, PhPhoneDisconnect} from '@phosphor-icons/vue';

const props = defineProps<SwitchRootProps & { class?: HTMLAttributes['class'] }>()

const emits = defineEmits<SwitchRootEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

</script>

<template>
      <SwitchRoot
      v-bind="forwarded"
     :class="cn(
       'peer inline-flex h-12 w-24 shrink-0 p-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm  rounded-md transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input',
       props.class,
       props.checked ? 'bg-toolbar-terminate' : 'bg-toolbar-control'
     )"
    >
      <SwitchThumb
        class="w-11 h-11 my-auto mx-0 px-0 bg-toolbar-background shadow-sm rounded-10 transition-transform duration-200 translate-x-0 will-change-transform data-[state=checked]:translate-x-[48px] flex justify-center items-center"
      >
      <span>
        <PhPhone v-if="props.checked" class="w-6 h-6"/>
        <PhPhoneDisconnect v-else class="w-6 h-6"/>
      </span>
      </SwitchThumb>

    </SwitchRoot>


</template>