import axios, {AxiosError} from "axios";
import type {AxiosRequestConfig, ResponseType} from "axios";
import {ref, type Ref} from "vue";
import {sha256} from "@/practice/crypto";
import {Message, type Agent, type LanguageSettings, type UserSettings} from "@/types/model";
import type {AgentTemplate, Voice} from "@/types/agents";

axios.defaults.withCredentials = true;

export const WS_UPLOAD_END = 0;
export const WS_UPLOAD_SILENCE = 1;
export const WS_UPLOAD_DATA = 2;
export const WS_DOWNLOAD_TEXT = 3;
export const WS_DOWNLOAD_LOCKED_IN= 4;
export const WS_DOWNLOAD_AUDIO_16000 = 5;
export const WS_DOWNLOAD_AUDIO_48000 = 6;
export const WS_DOWNLOAD_END = 7;
export const WS_DOWNLOAD_TRANSLATION_RESULT = 8;
export const WS_PLAYING_AI_AUDIO_START= 9;
export const WS_PLAYING_AI_AUDIO= 10;
export const WS_PLAYING_AI_AUDIO_END = 11;
export const WS_UPDATED_MESSAGE = 12;
export const WS_DOWNLOAD_AUDIO_48000_TALK = 13;
export const WS_AI_READY = 14


export type ApiRes<T> = {
    data: Ref<T | null>,
    isFetching: Ref<boolean>,
    error: Ref<number | null>
}
export function apiRes<T>() : ApiRes<T> {
    const data: Ref<T|null> = ref(null);
     return {
         data,
         isFetching: ref(false),
         error: ref(null)
     }
}


export type CheckUsernameResponse = {
    usernameExists: boolean
}

export type CheckAllowlistResponse = {
    allowlisted: boolean
}

export type CreateUserResponse = {
    message: string
}

export type UpdateUserResponse = {}

export type LoginResponse = {
    success: boolean,
    message: string,
    user: UserSettings | null
}

export type LogoutResponse = {}

export type GetLoginStatusResponse = {
    loggedIn: boolean
}

export type GetUserResponse = {
    user: UserSettings,
    defaultPassword: boolean
}

export type CheckSentenceResponse = {
    feedback: string,
    score: number
}

export type GetLeaderboardResponse = {
    leaderboard: {name: string, userId: string, language: string, totalDuration: number, streak?: number}[],
    userDailyDuration: number,
    userWeeklyDuration: number,
    userStreak?: number
}

export type GetUserUsageResponse = {
    weeklyDuration: number,
    totalDuration: number
}

export type GetVoicesResponse = {
    voices: Voice[]
}

export type GetAvailableAgentTemplatesResponse = {
    agentTemplates: AgentTemplate[]
}

export type GetWebRTCCredentialsResponse = {
    username: string,
    password: string
}   
export type StartSessionResponse = {
    room: {
        id: string,
        name: string,
        url: string
    },
    token: string
}

export type SubscribeResponse = {
    url: string
}

export type CreateStripePortalSessionResponse = {
    url: string
}

export type GetAgentsResponse = {
    agents: Agent[]
}

export type GetAgentMessagesResponse = {
    sessions: Message[][]
}
export type GetLanguageSettingsResponse = {
    languageSettings: LanguageSettings
}

export type UpdateLanguageSettingsResponse = {}

export type Kickstart = {
    id: string,
    description: string,
    prompt: string,
    type: string
}


export type LocalizedKickstart = {
    kickstart: Kickstart,
    language: string,
    localized_description: string
}

export type GetLocalizedKickstartsResponse = {
    studentLifeKickstarts: LocalizedKickstart[],
    topicKickstarts: LocalizedKickstart[],
    scenarioKickstarts: LocalizedKickstart[],
    grammarKickstarts: LocalizedKickstart[]
}
export type CorrectMessageResponse = {
    correctionSummary: string,
    score: number
}

export type DeleteUserResponse = {}

class PracticeAPI {
    domainPort: string;

    constructor(domainPort: string) {
        this.domainPort = domainPort;
    }

    private handleAPICall(url: string, inputData: Object, outputData: Ref, isFetching: Ref<boolean>, errorOut: Ref<number | null>, responseType?: ResponseType) {
        isFetching.value = true;
        errorOut.value = null;
        const config: AxiosRequestConfig = {
            method: 'post',
            url: this.domainPort + url,
            data: inputData,
            responseType,
        }
        return axios(config).then((response) =>  {
            console.log("POST /", url, " response: ", response);
            if (!response.data) {
                outputData.value = {};
            } else {
                outputData.value = response.data; //@ts-ignore
            }
            isFetching.value = false;
        }).catch((error: AxiosError) => {
            console.log("POST /", url, " error: ", error.response, error.message);
            if (error.response) {
                if (error.response.data !== null && typeof error.response.data === 'object') {
                    if ("error" in error.response.data && typeof error.response.data.error === 'number') {
                        errorOut.value = error.response.data.error;
                    } else if ("error" in error.response.data) {
                        errorOut.value = error.response.data.error as number;
                    } else {
                        errorOut.value = 0;
                    }
                } else {
                    errorOut.value = 0;
                }
            } else {
                errorOut.value = 0;
            }
            isFetching.value = false;
        });
    }


    async checkUsername(username: string, result: ApiRes<CheckUsernameResponse>) : Promise<void> {
        return this.handleAPICall("/check_username", {username}, result.data, result.isFetching, result.error);
    }
    async checkAllowlist(username: string, result: ApiRes<CheckAllowlistResponse>) : Promise<void> {
        return this.handleAPICall("/check_allowlist", {username}, result.data, result.isFetching, result.error);
    }
    async create_user(username: string, password: string, result: ApiRes<CreateUserResponse>) : Promise<void> {
        return sha256(password).then((prehashed: string) => {
            return this.handleAPICall("/create_user", {username, prehashed}, result.data, result.isFetching, result.error);
        })
    }
    async login(username: string, password: string, result: ApiRes<LoginResponse>) : Promise<void> {
        return sha256(password).then((prehashed: string) => {
            return this.handleAPICall("/login", {username, prehashed}, result.data, result.isFetching, result.error);
        })
    }
    async update_user(result: ApiRes<UpdateUserResponse>, partialUserSettings: Partial<UserSettings>): Promise<void> {
        const updateData: Partial<UserSettings> = {};
        for (const [key, value] of Object.entries(partialUserSettings)) {
            if (value !== undefined) {
                //@ts-ignore
                updateData[key as keyof UserSettings] = value;
            }
        }
        return this.handleAPICall("/update_user", updateData, result.data, result.isFetching, result.error);
    }
    async deleteUser(prehashed: string, result: ApiRes<DeleteUserResponse>) : Promise<void> {
        return this.handleAPICall("/delete_user", {prehashed}, result.data, result.isFetching, result.error);
    }
    
    async logout(result: ApiRes<LogoutResponse>) : Promise<void> {
        return this.handleAPICall("/logout", {}, result.data, result.isFetching, result.error);
    }

    async getLoginStatus(result: ApiRes<GetLoginStatusResponse>) : Promise<void> {
        return this.handleAPICall("/get_login_status", {}, result.data, result.isFetching, result.error);
    }
     async getUser(result: ApiRes<GetUserResponse>) : Promise<void> {
        return this.handleAPICall("/get_user", {}, result.data, result.isFetching, result.error);
    }
    async getAvailableAgentTemplates(result: ApiRes<GetAvailableAgentTemplatesResponse>) : Promise<void> {
        return this.handleAPICall("/get_available_agent_templates", {}, result.data, result.isFetching, result.error);
    }
    async checkSentence(sentence: string, previousMessages: Message[], result: ApiRes<CheckSentenceResponse>) : Promise<void> {
        return this.handleAPICall("/check_sentence", {sentence, previousMessages}, result.data, result.isFetching, result.error);
    }
    async getLeaderboard(result: ApiRes<GetLeaderboardResponse>) : Promise<void> {
        return this.handleAPICall("/get_leaderboard", {}, result.data, result.isFetching, result.error);
    }
    async getUserUsage(result: ApiRes<GetUserUsageResponse>) : Promise<void> {
        return this.handleAPICall("/get_user_usage", {}, result.data, result.isFetching, result.error);
    }
    async startSession(result: ApiRes<StartSessionResponse>): Promise<void> {
        return this.handleAPICall("/start_session", {}, result.data, result.isFetching, result.error);
    }
    async subscribe(priceId: string, result: ApiRes<SubscribeResponse>): Promise<void> {
        return this.handleAPICall("/subscribe", {priceId}, result.data, result.isFetching, result.error);
    }
    async createStripePortalSession(result: ApiRes<CreateStripePortalSessionResponse>): Promise<void> {
        return this.handleAPICall("/create_stripe_portal_session", {}, result.data, result.isFetching, result.error);
    }
    async getAgents(result: ApiRes<GetAgentsResponse>) : Promise<void> {
        return this.handleAPICall("/get_agents", {}, result.data, result.isFetching, result.error);
    }
    async getAgentMessages(result: ApiRes<GetAgentMessagesResponse>, agentId: string) : Promise<void> {
        return this.handleAPICall("/get_agent_messages", {agentId}, result.data, result.isFetching, result.error);
    }
    async getLanguageSettings(result: ApiRes<GetLanguageSettingsResponse>): Promise<void> {
        return this.handleAPICall("/get_language_settings", {}, result.data, result.isFetching, result.error);
    }
    async getLanguageSettingsForLanguage(language: string, result: ApiRes<GetLanguageSettingsResponse>): Promise<void> {
        return this.handleAPICall("/get_language_settings_for_language", {language: language}, result.data, result.isFetching, result.error);
    }
    async updateLanguageSettings(result: ApiRes<UpdateLanguageSettingsResponse>, partialLanguageSettings: Partial<LanguageSettings>): Promise<void> {
        return this.handleAPICall("/update_language_settings", partialLanguageSettings, result.data, result.isFetching, result.error);
    }
    async getLocalizedKickstarts(result: ApiRes<GetLocalizedKickstartsResponse>): Promise<void> {
        return this.handleAPICall("/get_localized_kickstarts", {}, result.data, result.isFetching, result.error);
    }
    async correctMessage(previousMessages: string[], message: string, language: string, result: ApiRes<CorrectMessageResponse>): Promise<void> {
        return this.handleAPICall("/correct_message", {previousMessages, message, language}, result.data, result.isFetching, result.error);
    }
}

export {
    PracticeAPI,
}
