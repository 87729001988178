import { LocaleToLanguage } from "../messages";

export enum ClientErrorType {
    NameRequired = 1,
    NetworkError = 2,
}

export const clientErrorStrings: { [key: string]: { [key: number]: string } } = {
    afrikaans: {
        1: "Voer asseblief jou naam in",
        2: "Netwerkfout",
    },
    arabic: {
        1: "الرجاء إدخال اسمك",
        2: "خطأ في الشبكة",
    },
    armenian: {
        1: "Խնդրում ենք մուտքագրել ձեր անունը",
        2: "Ցանցային սխալ",
    },
    azerbaijani: {
        1: "Zəhmət olmasa adınızı daxil edin",
        2: "Şəbəkə xətası",
    },
    belarusian: {
        1: "Калі ласка, увядзіце ваша імя",
        2: "Памылка сеткі",
    },
    bosnian: {
        1: "Molimo unesite svoje ime",
        2: "Greška mreže",
    },
    bulgarian: {
        1: "Моля, въведете вашето име",
        2: "Мрежова грешка",
    },
    catalan: {
        1: "Si us plau, introduïu el vostre nom",
        2: "Error de xarxa",
    },
    mandarin_chinese: {
        1: "请输入您的姓名",
        2: "网络错误",
    },
    croatian: {
        1: "Molimo unesite svoje ime",
        2: "Mrežna pogreška",
    },
    czech: {
        1: "Prosím zadejte své jméno",
        2: "Chyba sítě",
    },
    danish: {
        1: "Indtast venligst dit navn",
        2: "Netværksfejl",
    },
    dutch: {
        1: "Voer alstublieft uw naam in",
        2: "Netwerkfout",
    },
    english: {
        1: "Please enter your name",
        2: "Network error",
    },
    estonian: {
        1: "Palun sisestage oma nimi",
        2: "Võrgu viga",
    },
    finnish: {
        1: "Ole hyvä ja syötä nimesi",
        2: "Verkkovirhe",
    },
    french: {
        1: "Veuillez entrer votre nom",
        2: "Erreur réseau",
    },
    galician: {
        1: "Por favor, introduce o teu nome",
        2: "Erro de rede",
    },
    german: {
        1: "Bitte geben Sie Ihren Namen ein",
        2: "Netzwerkfehler",
    },
    greek: {
        1: "Παρακαλώ εισάγετε το όνομά σας",
        2: "Σφάλμα δικτύου",
    },
    hebrew: {
        1: "אנא הכנס את שמך",
        2: "שגיאת רשת",
    },
    hindi: {
        1: "कृपया अपना नाम दर्ज करें",
        2: "नेटवर्क त्रुटि",
    },
    hungarian: {
        1: "Kérjük, adja meg a nevét",
        2: "Hálózati hiba",
    },
    icelandic: {
        1: "Vinsamlegast sláðu inn nafnið þitt",
        2: "Netvilla",
    },
    indonesian: {
        1: "Silakan masukkan nama Anda",
        2: "Kesalahan jaringan",
    },
    italian: {
        1: "Per favore, inserisci il tuo nome",
        2: "Errore di rete",
    },
    japanese: {
        1: "お名前を入力してください",
        2: "ネットワークエラー",
    },
    kannada: {
        1: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
        2: "ನೆಟ್‌ವರ್ಕ್ ದೋಷ",
    },
    kazakh: {
        1: "Атыңызды енгізіңіз",
        2: "Желі қатесі",
    },
    korean: {
        1: "이름을 입력해 주세요",
        2: "네트워크 오류",
    },
    latvian: {
        1: "Lūdzu, ievadiet savu vārdu",
        2: "Tīkla kļūda",
    },
    lithuanian: {
        1: "Prašome įvesti savo vardą",
        2: "Tinklo klaida",
    },
    macedonian: {
        1: "Ве молиме внесете го вашето име",
        2: "Мрежна грешка",
    },
    malay: {
        1: "Sila masukkan nama anda",
        2: "Ralat rangkaian",
    },
    marathi: {
        1: "कृपया आपले नाव प्रविष्ट करा",
        2: "नेटवर्क त्रुटी",
    },
    maori: {
        1: "Tēnā koa, tāurua tō ingoa",
        2: "Hapa whatunga",
    },
    nepali: {
        1: "कृपया आफ्नो नाम प्रविष्ट गर्नुहोस्",
        2: "नेटवर्क त्रुटि",
    },
    norwegian: {
        1: "Vennligst skriv inn navnet ditt",
        2: "Nettverksfeil",
    },
    persian: {
        1: "لطفا نام خود را وارد کنید",
        2: "خطای شبکه",
    },
    polish: {
        1: "Proszę podać swoje imię",
        2: "Błąd sieci",
    },
    portuguese: {
        1: "Por favor, insira seu nome",
        2: "Erro de rede",
    },
    romanian: {
        1: "Vă rugăm să introduceți numele dvs",
        2: "Eroare de rețea",
    },
    russian: {
        1: "Пожалуйста, введите ваше имя",
        2: "Ошибка сети",
    },
    serbian: {
        1: "Молимо вас да унесете своје име",
        2: "Грешка мреже",
    },
    slovak: {
        1: "Prosím, zadajte svoje meno",
        2: "Chyba siete",
    },
    slovenian: {
        1: "Prosimo, vnesite svoje ime",
        2: "Napaka omrežja",
    },
    spanish: {
        1: "Por favor, ingrese su nombre",
        2: "Error de red",
    },
    swahili: {
        1: "Tafadhali ingiza jina lako",
        2: "Hitilafu ya mtandao",
    },
    swedish: {
        1: "Vänligen ange ditt namn",
        2: "Nätverksfel",
    },
    tagalog: {
        1: "Mangyaring ilagay ang iyong pangalan",
        2: "Error sa network",
    },
    tamil: {
        1: "உங்கள் பெயரை உள்ளிடவும்",
        2: "பிணைய பிழை",
    },
    thai: {
        1: "กรุณาใส่ชื่อของคุณ",
        2: "ข้อผิดพลาดของเครือข่าย",
    },
    turkish: {
        1: "Lütfen adınızı girin",
        2: "Ağ hatası",
    },
    ukrainian: {
        1: "Будь ласка, введіть ваше ім'я",
        2: "Помилка мережі",
    },
    urdu: {
        1: "براہ کرم اپنا نام درج کریں",
        2: "نیٹ ورک کی خرابی",
    },
    vietnamese: {
        1: "Vui lòng nhập tên của bạn",
        2: "Lỗi mạng",
    },
    welsh: {
        1: "Rhowch eich enw",
        2: "Gwall rhwydwaith",
    },
};

export const getClientErrorMessagesFromLanguage = (language: string) => {
    return clientErrorStrings[language] || clientErrorStrings['english'];
};

export function getClientErrorMessagesFromLocale(locale: string): { [key: number]: string } {
    return getClientErrorMessagesFromLanguage(LocaleToLanguage[locale]);
}