<script setup lang="ts">
import router from "@/router";

import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { Rocket } from 'lucide-vue-next'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import IssenHeader from "@/practice/IssenHeader.vue";
import { Checkbox } from '@/components/ui/checkbox'
import { ref, watch, type Ref } from "vue";
import { getMessagesFromLanguage, LocaleToLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type CheckAllowlistResponse, type CheckUsernameResponse, type CreateStripePortalSessionResponse, type GetAvailableAgentTemplatesResponse, type GetLanguageSettingsResponse, type GetUserResponse, type GetUserUsageResponse, PracticeAPI, type SubscribeResponse, type UpdateLanguageSettingsResponse, type UpdateUserResponse } from "@/practice/api";
import { protocol, domainPort } from "@/url";
import { UserStatus, ProficiencyLevel, TutorFormalness, TutorLanguageUsage, StudentGrammarHelp } from "@/types/model";
import { Slider } from "@/components/ui/slider";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
const languages = Object.values(LocaleToLanguage).sort();
const sourceLanguage = ref("");
const pageStrings = ref(getMessagesFromLanguage(getDefaultSourceLanguage()));
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const getUserResult = apiRes<GetUserResponse>();
const updateUserResult = apiRes<UpdateUserResponse>();
const targetLanguage = ref("");
const selectedAgentTemplateId = ref("");
const formalness = ref("");
const proficiencyLevel = ref("");
const tutorLanguageUsage = ref("");
const grammarCorrection = ref("");
const noiseCancellation = ref(true);
const name = ref("");
const tutorPatience = ref([5]);
const speechSpeedRatio = ref([1.0]);
const dailyMinutesGoal = ref([60]);
const hasOnboarded = ref(false);
const usageResult = apiRes<GetUserUsageResponse>();
const availableAgentTemplates = apiRes<GetAvailableAgentTemplatesResponse>();
const weeklyUsage = ref(-1);
const totalHistoricalUsage = ref(-1);
const languageSettings = apiRes<GetLanguageSettingsResponse>();
const updateLanguageSettingsResult = apiRes<UpdateLanguageSettingsResponse>();
const initialized = ref(false);
const timesEditedTutorPatience = ref(0);
const timesEditedSpeechSpeedRatio = ref(0);
const timesEditedDailyMinutesGoal = ref(0);


onMounted(async () => {
  await API.getUser(getUserResult);
  if (!getUserResult.data.value) {
    router.push("/email?redirect=settings");
    return;
  }
  if (getUserResult.data.value && getUserResult.data.value.user.sourceLang) {
    sourceLanguage.value = getUserResult.data.value.user.sourceLang;
    pageStrings.value = getMessagesFromLanguage(sourceLanguage.value);
  }
  await API.getAvailableAgentTemplates(availableAgentTemplates);
  if (getUserResult.data.value) {
    console.log(getUserResult.data.value.user);
    name.value = getUserResult.data.value.user.name;
    tutorPatience.value = [getUserResult.data.value.user.tutorPatience];
    speechSpeedRatio.value = [getUserResult.data.value.user.speechSpeedRatio];
    selectedAgentTemplateId.value = getUserResult.data.value.user.selectedAgentTemplateId;
    hasOnboarded.value = getUserResult.data.value.user.hasOnboarded;
    dailyMinutesGoal.value = [getUserResult.data.value.user.dailyMinutesGoal];
    noiseCancellation.value = getUserResult.data.value.user.noiseCancellation;
    sourceLanguage.value = getUserResult.data.value.user.sourceLang;
    pageStrings.value = getMessagesFromLanguage(sourceLanguage.value);
  }

  if (getUserResult.data.value && getUserResult.data.value.user.hasOnboarded) {
    await API.getLanguageSettings(languageSettings);
    if (languageSettings.data.value) {
      targetLanguage.value = languageSettings.data.value.languageSettings.language;
      formalness.value = languageSettings.data.value.languageSettings.formalness;
      proficiencyLevel.value = languageSettings.data.value.languageSettings.proficiencyLevel;
      tutorLanguageUsage.value = languageSettings.data.value.languageSettings.tutorLanguageUsage;
      grammarCorrection.value = languageSettings.data.value.languageSettings.grammarCorrection;
    }
  }
  await API.getUserUsage(usageResult);
  if (usageResult.data.value) {
    weeklyUsage.value = usageResult.data.value.weeklyDuration;
    totalHistoricalUsage.value = usageResult.data.value.totalDuration;
  }
  getUserResult.isFetching.value = false;
  initialized.value = true;
})

watch(targetLanguage, async (newValue) => {
  if (!initialized.value) {
    return;
  }
  if (newValue !== null) {
    if (selectedAgentTemplateId.value !== "") {
      const currentSelectedAgentTemplate = availableAgentTemplates.data.value?.agentTemplates.find(at => at.agentTemplateId === selectedAgentTemplateId.value);
      if (currentSelectedAgentTemplate?.language !== newValue) {
        // TODO: have this be smart and get a tutor that the user has used before rather than a random one
        selectedAgentTemplateId.value = availableAgentTemplates.data.value?.agentTemplates.find(at => at.language === newValue)?.agentTemplateId ?? "";
        await API.update_user(updateUserResult, {
          selectedAgentTemplateId: selectedAgentTemplateId.value,
        });
        if (getUserResult.data.value && getUserResult.data.value.user.hasOnboarded) {
          await API.getLanguageSettingsForLanguage(newValue, languageSettings);
          if (languageSettings.data.value) {
            targetLanguage.value = languageSettings.data.value.languageSettings.language;
            formalness.value = languageSettings.data.value.languageSettings.formalness;
            proficiencyLevel.value = languageSettings.data.value.languageSettings.proficiencyLevel;
            tutorLanguageUsage.value = languageSettings.data.value.languageSettings.tutorLanguageUsage;
            grammarCorrection.value = languageSettings.data.value.languageSettings.grammarCorrection;
          }
        }
      }
    }
  }
});

async function goToApp() {
  router.push("/");
}


async function goToSubscribe() {
  router.push("/subscribe");
}
async function manageAccount() {
  router.push("/manage-account");
}

watch(sourceLanguage, async (newValue) => {
  console.log("sourceLanguage", newValue);
  if (newValue !== null) {
    sourceLanguage.value = newValue;
    pageStrings.value = getMessagesFromLanguage(newValue);
    await API.update_user(updateUserResult, {
      sourceLang: newValue,
    });
  }
})

watch(selectedAgentTemplateId, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.update_user(updateUserResult, {
      selectedAgentTemplateId: newValue,
    });
  }
});

watch(noiseCancellation, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.update_user(updateUserResult, {
      noiseCancellation: newValue,
    });
  }
});

watch(formalness, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.updateLanguageSettings(updateLanguageSettingsResult, {
      language: targetLanguage.value,
      formalness: newValue,
    });
  }
});

watch(proficiencyLevel, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.updateLanguageSettings(updateLanguageSettingsResult, {
      language: targetLanguage.value,
      proficiencyLevel: newValue,
    });
  }
});

watch(tutorLanguageUsage, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.updateLanguageSettings(updateLanguageSettingsResult, {
      language: targetLanguage.value,
      tutorLanguageUsage: newValue,
    });
  }
});

watch(grammarCorrection, async (newValue) => {
  if (newValue !== null && initialized.value) {
    await API.updateLanguageSettings(updateLanguageSettingsResult, {
      language: targetLanguage.value,
      grammarCorrection: newValue,
    });
  }
});

watch(tutorPatience, async (newValue) => {
  if (newValue !== null && initialized.value) {
    const thisTime = timesEditedTutorPatience.value;
    timesEditedTutorPatience.value += 1;
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (thisTime + 1 === timesEditedTutorPatience.value) { // This means the user has stopped editing
      try {
        await API.update_user(updateUserResult, {
          tutorPatience: tutorPatience.value[0],
        });
      } catch (error) {
        console.error("Error updating tutor patience settings:", error);
      }
    }
  }
});

watch(speechSpeedRatio, async (newValue) => {
  if (newValue !== null && initialized.value) {
    const thisTime = timesEditedSpeechSpeedRatio.value;
    timesEditedSpeechSpeedRatio.value += 1;
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (thisTime + 1 === timesEditedSpeechSpeedRatio.value) { // This means the user has stopped editing
      try {
        await API.update_user(updateUserResult, {
          speechSpeedRatio: speechSpeedRatio.value[0],
        });
      } catch (error) {
        console.error("Error updating speech speed ratio settings:", error);
      }
    }
  }
});

watch(dailyMinutesGoal, async (newValue) => {
  if (newValue !== null && initialized.value) {
    const thisTime = timesEditedDailyMinutesGoal.value;
    timesEditedDailyMinutesGoal.value += 1;
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (thisTime + 1 === timesEditedDailyMinutesGoal.value) { // This means the user has stopped editing
      try {
        await API.update_user(updateUserResult, {
          dailyMinutesGoal: dailyMinutesGoal.value[0],
        });
      } catch (error) {
        console.error("Error updating daily minutes goal settings:", error);
      }
    }
  }
});

</script>

<style scoped>
.settings-bg {
  background: var(--bg-chat-base);
  min-height: 100vh;
  width: 100%;
}
</style>

<template>
  <div class="settings-bg">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="() => { }" :postLogout="() => { }" />
    </div>
    <div class="flex flex-col flex-grow items-center overflow-y-auto">
      <div class="w-[350px] md:w-[500px] lg:w-[600px] mb-5">
        <span class="text-h2xl md:text-h3xl text-left">{{ pageStrings.settings }}</span>
        <Button @click="goToApp" variant="secondary" class="float-right"
          :disabled="getUserResult.isFetching.value || updateUserResult.isFetching.value">
          {{ pageStrings.goToApp }}
        </Button>
      </div>
      <Card class="w-[350px] md:w-[500px] lg:w-[600px] mb-10" v-if="getUserResult.data.value">
        <CardHeader class="pb-1 sticky top-0 z-10 mb-4">
          <CardTitle>
            <div class="flex justify-between items-center w-full">
              <span class="text-xl md:text-2xl font-normal">{{ pageStrings.subscription }}</span>
              <Button @click="goToSubscribe" v-if="getUserResult.data.value.user.userStatus !== UserStatus.SUBSCRIBED"
                variant="secondary" :disabled="getUserResult.isFetching.value || updateUserResult.isFetching.value">
                {{ pageStrings.subscribe }}
              </Button>
              <Button @click="manageAccount" variant="secondary"
                v-if="getUserResult.data.value.user.userStatus === UserStatus.SUBSCRIBED" class=""
                :disabled="getUserResult.isFetching.value || updateUserResult.isFetching.value">
                {{ pageStrings.manageAccount }}
              </Button>
            </div>

          </CardTitle>
        </CardHeader>
        <CardContent>
          <p class="text-md md:text-lg mb-2 mt-0">{{ pageStrings.subscriptionStatus }}</p>
          <p class="text-sm md:text-md">{{ getUserResult.data.value.user.userStatus === UserStatus.SUBSCRIBED ?
            pageStrings.subscribed : pageStrings.notSubscribed }}</p>
          <p v-if="totalHistoricalUsage !== -1" class="text-md md:text-lg mb-2 mt-0 mt-4">{{ pageStrings.usage }}</p>
          <p v-if="totalHistoricalUsage !== -1" class="text-sm md:text-md">{{ totalHistoricalUsage }} {{
            pageStrings.minutes }}</p>

        </CardContent>
      </Card>

      <Card class="w-[350px] md:w-[500px] lg:w-[600px] mb-10" v-if="getUserResult.data.value && hasOnboarded">
        <CardHeader class="pb-1 sticky top-0 z-10 mb-4">
          <CardTitle>

            <div class="flex justify-between items-center w-full">
              <span class="text-xl md:text-2xl font-normal">{{ pageStrings.language }}</span>
            </div>

          </CardTitle>
        </CardHeader>
        <CardContent>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <p class="text-md md:text-lg mb-2 mt-4"> {{ pageStrings.nativeLanguage }}</p>
            <Select v-model="sourceLanguage" class="mb-4" v-if="hasOnboarded">
              <SelectTrigger class="w-[280px] md:w-[230px]">
                <SelectValue :placeholder="pageStrings.selectLanguage" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem v-for="language in languages" :key="language" :value="language">
                  {{ language.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                    word.slice(1).toLowerCase()).join(' ') }}
                </SelectItem>
              </SelectContent>
            </Select>
            <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.language }}</p>
            <Select v-model="targetLanguage" class="mb-4" v-if="hasOnboarded">
              <SelectTrigger class="w-[280px] md:w-[230px]">
                <SelectValue :placeholder="pageStrings.selectLanguage" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem v-for="language in languages" :key="language" :value="language">
                  {{ language.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                    word.slice(1).toLowerCase()).join(' ') }}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          <Separator class="my-4 mb-2 bg-border-strong" />
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.tutor }}</p>
              <Select v-model="selectedAgentTemplateId" class="mb-4">
                <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                  <SelectValue :placeholder="pageStrings.selectTutor" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem
                    v-for="agentTemplate in availableAgentTemplates.data.value?.agentTemplates.filter(at => at.language === targetLanguage)"
                    :key="agentTemplate.agentTemplateId" :value="agentTemplate.agentTemplateId">
                    {{ agentTemplate.name + " (" + agentTemplate.homeCity + ")" }}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div>
              <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.formalness }}</p>
              <Select v-model="formalness" class="mb-4">
                <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                  <SelectValue :placeholder="pageStrings.selectLanguage" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem v-for="form in Object.values(TutorFormalness)" :key="form" :value="form">
                    {{ form.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                      word.slice(1).toLowerCase()).join(' ')}}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div>
              <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.proficiencyLevel }}</p>
              <Select v-model="proficiencyLevel" class="mb-4">
                <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                  <SelectValue :placeholder="pageStrings.selectProficiencyLevel" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem v-for="proficiency in Object.values(ProficiencyLevel)" :key="proficiency"
                    :value="proficiency">
                    {{ proficiency.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                      word.slice(1).toLowerCase()).join(' ')}}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div>
              <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.tutorLanguageUsage }}</p>
              <Select v-model="tutorLanguageUsage" class="mb-4">
                <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                  <SelectValue :placeholder="pageStrings.selectTutorLanguageUsage" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem v-for="tutorLanguageUsage in Object.values(TutorLanguageUsage)" :key="tutorLanguageUsage"
                    :value="tutorLanguageUsage">
                    {{ tutorLanguageUsage.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                      word.slice(1).toLowerCase()).join(' ')}}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div>
              <p class="text-md md:text-lg mb-2 mt-4">{{ pageStrings.grammarCorrection }}</p>
              <Select v-model="grammarCorrection" class="mb-4">
                <SelectTrigger class="w-[280px] md:w-[200px] lg:w-[240px]">
                  <SelectValue :placeholder="pageStrings.selectGrammarCorrection" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem v-for="grammarCorrection in Object.values(StudentGrammarHelp)" :key="grammarCorrection"
                    :value="grammarCorrection">
                    {{ grammarCorrection.split('_').map((word: string) => word.charAt(0).toUpperCase() +
                      word.slice(1).toLowerCase()).join(' ')}}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

        </CardContent>
      </Card>


      <Card class="w-[350px] md:w-[500px] lg:w-[600px] mb-14" v-if="getUserResult.data.value">
        <CardHeader class="pb-1 sticky top-0 z-10 mb-4">
          <CardTitle>

            <div class="flex justify-between items-center w-full">
              <span class="text-xl md:text-2xl font-normal">{{ pageStrings.tutor }}</span>
            </div>

          </CardTitle>
        </CardHeader>
        <CardContent>

          <p class="text-md md:text-lg mt-4">{{ pageStrings.tutorPatience }}: {{ tutorPatience?.[0] }}</p>
          <Slider class="mt-2" v-model="tutorPatience" :max="10" :min="1" :step="1" trackColor="bg-bg-chat-sent"
            thumbColor="bg-fg-interactive" />
          <span class="flex justify-between mt-2 text-gray-500">
            <span class="text-xs md:text-sm">{{ pageStrings.tutorPatienceDescription }}</span>
          </span>
          <p class="text-md md:text-lg mt-4">{{ pageStrings.speechSpeedRatio }}: {{ speechSpeedRatio?.[0] }}</p>
          <Slider class="mt-2" v-model="speechSpeedRatio" :max="1.2" :min="0.7" :step="0.05"
            trackColor="bg-bg-chat-sent" thumbColor="bg-fg-interactive" />
          <span class="flex justify-between mt-2 text-gray-500">
            <span class="text-xs md:text-sm">{{ pageStrings.speechSpeedRatioDescription }}</span>
          </span>
          <p class="text-md md:text-lg mt-4">{{ pageStrings.dailyMinutesGoal }} {{ dailyMinutesGoal?.[0] }}</p>
          <Slider class="mt-2" v-model="dailyMinutesGoal" :max="60" :min="5" :step="5" trackColor="bg-bg-chat-sent"
            thumbColor="bg-fg-interactive" m />
          <span class="flex justify-between mt-2 text-gray-500">
            <span class="text-xs md:text-sm">{{ pageStrings.dailyMinutesGoalDescription }}</span>
          </span>
          <div class="flex items-center mt-4">
            <Checkbox :checked="noiseCancellation" @update:checked="noiseCancellation = $event" />
            <p class="text-md md:text-lg ml-4">{{ pageStrings.noiseCancellation }}</p>
          </div>
        </CardContent>
      </Card>
    </div>
  </div>
</template>
