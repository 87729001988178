<script setup lang="ts">
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import IssenHeader from "@/practice/IssenHeader.vue";
import { nextTick, onMounted, ref, watch, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { apiRes, PracticeAPI, type GetAgentMessagesResponse, type GetAgentsResponse, type GetUserResponse } from "@/practice/api";
import { Button } from "@/components/ui/button";
import { domainPort, protocol } from "@/url";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import type { Message } from '@/types/model';
import router from '@/router';
const sourceLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(sourceLanguage.value));
const getUserResult = apiRes<GetUserResponse>();
const getAgentsResult = apiRes<GetAgentsResponse>();
const getAgentMessagesResult = apiRes<GetAgentMessagesResponse>();
const selectedAgentId: Ref<string> = ref("");
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const sessions: Ref<Message[][]> = ref([]);
const messageContainer: Ref<HTMLElement | null> = ref(null);

onMounted(async () => {
  await API.getUser(getUserResult);
  if (getUserResult.data.value && getUserResult.data.value.user.sourceLang) {
    sourceLanguage.value = getUserResult.data.value.user.sourceLang;
    pageStrings.value = getMessagesFromLanguage(sourceLanguage.value);
  }
  const selectedAgentTemplateId = getUserResult.data.value?.user.selectedAgentTemplateId;
  await API.getAgents(getAgentsResult);
  if (getAgentsResult.data.value?.agents) {
    for (const agent of getAgentsResult.data.value.agents) {
      if (agent.agentTemplateId === selectedAgentTemplateId) {
        selectedAgentId.value = agent.agentId;
      }
    }
  }
  setTimeout(() => {
    scrollToBottom();
  }, 100);
})

const scrollToBottom = () => {
  nextTick(() => {
    if (messageContainer.value) {
      messageContainer.value.scrollTop = messageContainer.value.scrollHeight;
    }
  });
};

const getAgentName = (agentId: string): string => {
  if (!getAgentsResult.data.value?.agents) return '';
  const agent = getAgentsResult.data.value.agents.find(a => a.agentId === agentId);
  return agent ? agent.name : '';
};
async function goToApp() {
  router.push("/");
}
watch(selectedAgentId, async (newAgentId) => {
  if (newAgentId) {
    await API.getAgentMessages(getAgentMessagesResult, newAgentId);
    sessions.value = (getAgentMessagesResult.data.value?.sessions || []).slice(-50);
    scrollToBottom();
  }
});


</script>

<template>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="() => {}" :postLogout="() => {}"/>
    </div>
    <div class="flex-1 flex flex-col w-full">
      <div class="flex flex-grow justify-center items-center">
        <Card class="w-[90%] md:w-3/4 max-w-3xl bg-bg-base shadow-card-rest">
          <CardHeader>
            <CardTitle class="text-2xl">{{pageStrings.historicalMessages}}</CardTitle>
          </CardHeader>
          <CardContent>
            <div class="flex items-center justify-center mb-4" v-if="getAgentsResult.data.value?.agents">
              <Select v-model="selectedAgentId" class="ml-2" v-if="selectedAgentId !==''"> 
                <SelectTrigger class="w-[180px]">
                  <SelectValue :placeholder="pageStrings.selectTutor" />
                </SelectTrigger>
                <SelectContent class="bg-bg-base">
                  <SelectItem v-for="agent in getAgentsResult.data.value.agents" :key="agent.agentId" :value="agent.agentId">
                    {{ agent.name + " (" + agent.homeCity + ")" }}
                  </SelectItem>
                </SelectContent>
              </Select>
              <Button variant="secondary" @click="goToApp" class="ml-4 cursor-pointer"> 
                {{ pageStrings.goToApp }}
              </Button>
            </div>

            <div class="h-[calc(100vh-24rem)] overflow-y-auto p-4 border border-gray-200 rounded-lg" ref="messageContainer" v-if="getAgentMessagesResult.data.value?.sessions && getUserResult.data.value">
              <div class="flex flex-col">
                <div v-for="(session, session_index) in sessions" :key="session_index" class="mb-4">
                  <Separator 
                    v-if="session_index > 0"
                    class="mt-2 mb-6 bg-gray-300 text-red z-30" 
                    :label="new Date(session[0].time * 1000).toLocaleDateString() + ' ' + new Date(session[0].time * 1000).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})"
                  />

                  <div v-for="(message, index) in session" :key="index" class="mb-4">
                    <div :class="[
                      'p-2 rounded-lg overflow-hidden border',
                      message.fromId === getUserResult.data.value?.user.userId 
                        ? 'bg-[#e5e3dc] bg-opacity-40' 
                        : 'bg-[#d59880] bg-opacity-40',
                    ]">
                      <p class="text-xs sm:text-sm text-black">
                        <span class="font-bold">
                          {{message.fromId === getUserResult.data.value?.user.userId 
                            ? pageStrings.you 
                            : getAgentName(message.fromId)
                          }}
                        </span>:
                        <span v-for="(section, idx) in message.content" :key="idx">
                          <span v-html="section.content"></span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  </span>
</template>
