<script setup lang="ts">
import { PhChats, PhBookOpenText, PhPersonSimpleTaiChi, PhLightbulb, PhFilmSlate, PhCircleNotch  } from '@phosphor-icons/vue';
import { computed, onMounted, ref, type Ref } from 'vue';
import { Button } from '@/components/ui/button';
import type { GetLocalizedKickstartsResponse, Kickstart, LocalizedKickstart } from './api';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import Separator from './Separator.vue';
import type { UserSettings } from '@/types/model';

const props = defineProps<{
  pageStrings: { [key: string]: string },
  languageProficiency: string,
  startEnabled: boolean,
  clickedStart: (kickStartMessage?: LocalizedKickstart) => void,
  localizedKickstarts: GetLocalizedKickstartsResponse | null,
  user: UserSettings | null,
  isUserLoggedIn: boolean
}>();

const startWithKickstart = (kickstart: LocalizedKickstart) => {
  props.clickedStart(kickstart);
}

const isOnboarded = computed(() => {
  return props.user && props.user.hasOnboarded;
});
</script>

<style scoped>  
.bg-button-primary {
  background: var(--button-primary);
}
.bg-button-secondary {
  background: var(--button-secondary);
}


</style>

<template>  
    <span class="md:p-1 mb-8 md:px-4 flex flex-col items-center gap-4 shadow-elevation-toolbar rounded-lg w-full">
        <div :class="{'invisible': !(props.user && props.isUserLoggedIn && props.startEnabled)}" class="text-hmd md:text-hlg">
           {{ pageStrings.welcomeBack }} {{ props.user?.name}}
        </div>
        <div class="bg-toolbar-background rounded-sm cursor-pointer">
            <Button class="text-toolbar-text-primary" :disabled="!startEnabled" @click="clickedStart(undefined)">
                <PhCircleNotch v-if="!startEnabled" class="w-6 h-6 mr-1 animate-spin"/>
                <PhChats v-else class="w-6 h-6 mr-1"/>
                {{ isOnboarded ? pageStrings.openEnded : pageStrings.startLesson}}
            </Button>
        </div>

        <Separator :maxWidth="20" :label="pageStrings.or" v-if="isOnboarded"/>

        <span  v-if="isOnboarded" class="flex flex-row flex-wrap justify-center gap-3">
            <DropdownMenu v-if="localizedKickstarts && localizedKickstarts.grammarKickstarts.length > 0">
              <DropdownMenuTrigger as-child>
                <Button variant="secondary" class="" :disabled="!startEnabled">
                    <PhBookOpenText class="w-6 h-6 mr-1"/>
                    {{ pageStrings.grammarLesson}}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-60 bg-bg-chat-received shadow-card-rest">
                <DropdownMenuGroup>
                  <DropdownMenuLabel class="font-normal" >
                    <span class="font-bold">{{ languageProficiency === "advanced" ? pageStrings.advancedGrammar : (languageProficiency === "intermediate" ? pageStrings.intermediateGrammar : pageStrings.beginnerGrammar)}}</span>
                  </DropdownMenuLabel>
                      <DropdownMenuSeparator />
                    <DropdownMenuItem v-if="localizedKickstarts" 
                  v-for="kickstart in localizedKickstarts.grammarKickstarts" 
                  :key="kickstart.kickstart.id" @click="startWithKickstart(kickstart)"
                  class="cursor-pointer">
                          {{ kickstart.localized_description }}
                    </DropdownMenuItem>
                
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>

            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <Button variant="secondary" class="bg-bg-chat-received" :disabled="!startEnabled">
                    <PhPersonSimpleTaiChi class="w-6 h-6 mr-1"/>
                    {{ pageStrings.studentLifeKickstart }}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-48 bg-bg-chat-received shadow-card-rest">
                <DropdownMenuGroup>
                    <DropdownMenuItem v-if="localizedKickstarts" 
                  v-for="kickstart in localizedKickstarts.studentLifeKickstarts" 
                  :key="kickstart.kickstart.id" @click="startWithKickstart(kickstart)"
                  class="cursor-pointer">
                          {{ kickstart.localized_description }}
                    </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>

            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <Button variant="secondary" class="bg-bg-chat-received" :disabled="!startEnabled">
                    <PhLightbulb class="w-6 h-6 mr-1"/>
                    {{ pageStrings.topicKickstart }}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-54 bg-bg-chat-received shadow-card-rest">
                <DropdownMenuGroup>
                    <DropdownMenuItem v-if="localizedKickstarts" 
                  v-for="kickstart in localizedKickstarts.topicKickstarts" 
                  :key="kickstart.kickstart.id" @click="startWithKickstart(kickstart)"
                  class="cursor-pointer">
                          {{ kickstart.localized_description }}
                    </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>

            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <Button variant="secondary" class="bg-bg-chat-received" :disabled="!startEnabled">
                    <PhFilmSlate class="w-6 h-6 mr-1"/>
                    {{ pageStrings.scenarioKickstart }}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-56 bg-bg-chat-received shadow-card-rest">
                <DropdownMenuGroup>
                    <DropdownMenuItem v-if="localizedKickstarts" 
                  v-for="kickstart in localizedKickstarts.scenarioKickstarts" 
                  :key="kickstart.kickstart.id" @click="startWithKickstart(kickstart)"
                  class="cursor-pointer">
                          {{ kickstart.localized_description }}
                    </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
        </span>
    </span>
</template>