<script setup lang="ts">
import { PhBookOpenText, PhChats, PhMicrophone, PhMicrophoneSlash, PhGearSix, PhPersonSimpleTaiChi, PhLightbulb, PhFilmSlate, PhSpinner, PhArrowClockwise} from '@phosphor-icons/vue';
import { ToolbarSwitch } from '@/components/ui/toolbarswitch'
import { Slider } from '@/components/ui/slider'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import ConfirmDialog from './ConfirmDialog.vue';
import router from "@/router";
import { apiRes, PracticeAPI, type LocalizedKickstart, type UpdateUserResponse } from './api';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import type { UserSettings } from '@/types/model';
import { domainPort, protocol } from '@/url';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
const tooltipContent = ref("");
const tooltipShortcut = ref("");

const tooltipHovering = ref(false);
const tutorPatience = ref([0]);
const speechSpeedRatio = ref([0]);
const tutorMenuOpen = ref(false);
const tutorMenuRef = ref(null);
const pressedKeys = new Set();
const timesEditedTutorPatience = ref(0);
const timesEditedSpeechSpeedRatio = ref(0);
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const updateUserResult = apiRes<UpdateUserResponse>();


const props = defineProps<{
  pageStrings: { [key: string]: string },
  isMuted: boolean,
  toggleMute: () => void,
  aiReady: boolean,
  leaveCall: () => void,
  kickstart: LocalizedKickstart | null,
  user: UserSettings,
  availableDevices: Object[],
  selectedDevice: Object | null,
  updatingDevices: boolean,
}>();

const emit = defineEmits<{
  (e: 'reloadUser'): void,
  (e: 'updateDevices', devices: { mic?: string, speaker?: string }): void,
}>();

onMounted(() => {
  document.addEventListener("keydown", handleShortcut);
  tutorPatience.value = [props.user.tutorPatience];
  speechSpeedRatio.value = [props.user.speechSpeedRatio];
});

// Remove global listener on unmount
onUnmounted(() => {
  document.removeEventListener("keydown", handleShortcut);
});

const clickLeaveCall = () => {
    if (props.aiReady) {
        props.leaveCall();
    }
}
const clickedContinue = () => {
    router.push('/settings');
}
const isMac = () => {
    return navigator.userAgent.toLowerCase().indexOf('macintosh') > -1;
};

const getCmdCtrlKey = () => {
    if (isMac()) {
        return "⌘";
    } else {
        return "Ctrl+";
    }
}

const lessonStr = computed(() => {
    if (!props.kickstart || props.kickstart.kickstart.type === "open_ended") {
        return props.pageStrings.freeConversation || "";
    } else {
        if (props.kickstart.kickstart.type === "scenario") {
            return props.pageStrings.scenario;
        } else if (props.kickstart.kickstart.type === "topic") {
            return props.pageStrings.topic;
        } else if (props.kickstart.kickstart.type === "grammar") {
            return props.pageStrings.grammarLesson;
        } else if (props.kickstart.kickstart.type === "student_life") {
            return props.pageStrings.personalLife;
        } else {
            return props.kickstart.kickstart.type;
        }
    }
})

const lessonDescription = computed(() => {
    if (!props.kickstart || props.kickstart.kickstart.type === "open_ended") {
        return props.pageStrings.endCallToChange || "";
    } else {
        return props.kickstart.localized_description;
    }
})

const onHover = (event: any) => {
    if (!tooltipHovering.value && tooltipContent.value !== "") {
        return;
    }
    const hoveredId = event.target.id; // Access `data-id`
    tooltipHovering.value = true;
    if (hoveredId === "speed") {
        tooltipContent.value = props.pageStrings.tutorSpeedSettings;
        tooltipShortcut.value = getCmdCtrlKey() + "1";
    } else if (hoveredId === "mute") {
        if (props.isMuted) {
            tooltipContent.value = props.pageStrings.unmuteMic;
        } else {
            tooltipContent.value = props.pageStrings.muteMic;
        }
        tooltipShortcut.value = getCmdCtrlKey() + "M";
    } else if (hoveredId === "settings") {
        tooltipContent.value = props.pageStrings.goToSettings;
        tooltipShortcut.value = "";
    } else if (hoveredId === "leave") {
        tooltipContent.value = props.pageStrings.endLesson;
        tooltipShortcut.value = getCmdCtrlKey() + "3";
    }

};
const onLeave = () => {
    if (tooltipHovering.value) {
        tooltipHovering.value = false;
        tooltipContent.value = "";
        tooltipShortcut.value = "";
    }
}

watch(() => props.isMuted, (newValue) => {
    if (newValue) {
        tooltipContent.value = props.pageStrings.micMuted;
        tooltipShortcut.value = "";
    } else {
        tooltipContent.value = props.pageStrings.micUnmuted;
        tooltipShortcut.value = "";
    }
    tooltipHovering.value = false;
    // Clear tooltip after 2 seconds if content hasn't changed
    const timeoutId = setTimeout(() => {
        if (tooltipContent.value === (newValue ? props.pageStrings.micMuted : props.pageStrings.micUnmuted)) {
            tooltipContent.value = "";
            tooltipShortcut.value = "";
        }
    }, 1500);

    // Clean up timeout on next change
    return () => clearTimeout(timeoutId);
});

const toggleTutorMenu = () => {
    tutorMenuOpen.value = !tutorMenuOpen.value;
    if (tutorMenuOpen.value) {
        emit('updateDevices', {});
    }
}
const handleShortcut = (event: any) => {
    // Skip if key is being held down (repeat event)
    if (event.repeat || pressedKeys.has(event.key)) {
        return;
    }
    pressedKeys.add(event.key);
    // Remove key from pressedKeys after 500ms
    setTimeout(() => {
        pressedKeys.delete(event.key);
    }, 300);
    const isCommandKey = isMac() ? event.metaKey : event.ctrlKey;
    if (isCommandKey && event.key.toLowerCase() === 'm') {
        event.preventDefault(); // Prevent default action
        props.toggleMute();
    } else if (isCommandKey && event.key.toLowerCase() === '1') {
        event.preventDefault(); // Prevent default action
        toggleTutorMenu();
    } else if (isCommandKey && event.key.toLowerCase() === '3') {
        event.preventDefault(); // Prevent default action
        props.leaveCall();
    }
};
watch(tutorPatience, async (newValue, oldValue) => {
  if (newValue !== null && oldValue !== undefined && newValue[0] != 0) { 
    console.log("tutorPatience", newValue, oldValue);
    const thisTime = timesEditedTutorPatience.value;
    timesEditedTutorPatience.value += 1;
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (thisTime + 1=== timesEditedTutorPatience.value) { // This means the user has stopped editing
      await API.update_user(updateUserResult, {
        tutorPatience: tutorPatience.value[0],
      });
      emit('reloadUser');
    }
  }
});
watch(speechSpeedRatio, async (newValue, oldValue) => {
  if (newValue !== null && oldValue !== undefined && newValue[0] != 0) { 
    console.log("speechSpeedRatio", newValue, oldValue);
    const thisTime = timesEditedSpeechSpeedRatio.value;
    timesEditedSpeechSpeedRatio.value += 1;
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (thisTime + 1 === timesEditedSpeechSpeedRatio.value) { // This means the user has stopped editing
      await API.update_user(updateUserResult, {
        speechSpeedRatio: speechSpeedRatio.value[0],
      });
      emit('reloadUser');
    }
  }
});
</script>

<style scoped>  
    .tooltip-shortcut {
        color: hsla(0, 0%, 100%, 0.65);
    }
    .component-shadow {
        box-shadow: 0px 2px 4px 0px var(--Components-shadow, rgba(0, 0, 0, 0.09));
    }
</style>

<template>  

    <span
    class="h-[1.5rem] component-shadow rounded-xs text-toolbar-icon mb-2 p-[0.375rem] text-sm flex items-center"
        :class="{ 'bg-[#181818]': tooltipHovering, 'bg-toolbar-background': !tooltipHovering, 'invisible': tooltipContent === '' }">
        {{ tooltipContent}} <span v-if="tooltipShortcut" class="ml-1 tooltip-shortcut">{{ tooltipShortcut }}</span>
    </span>
    <span @keydown="handleShortcut" class="p-1 mb-4 md:mb-10 flex w-fit h-fit justify-start items-center bg-toolbar-background text-toolbar-text-primary shadow-toolbar rounded-lg">
        <span class="flex flex-row items-center rounded-md">
            <span class="w-10 h-10 mr-2 ml-1 bg-toolbar-control rounded-full flex justify-center items-center">
                <PhChats class="w-6 h-6" v-if="!kickstart || kickstart.kickstart.type === 'open_ended'"/>
                <PhBookOpenText class="w-6 h-6" v-if="kickstart && kickstart.kickstart.type === 'grammar'"/>
                <PhPersonSimpleTaiChi class="w-6 h-6" v-if="kickstart && kickstart.kickstart.type === 'student_life'"/>
                <PhLightbulb class="w-6 h-6" v-if="kickstart && kickstart.kickstart.type === 'topic'"/>
                <PhFilmSlate class="w-6 h-6" v-if="kickstart && kickstart.kickstart.type === 'scenario'"/>
            </span>

            <span class="flex flex-col pr-4 h-12 justify-center hidden md:flex">
                <p class="m-0 p-0 text-sm">{{ lessonStr }} </p>
                <p class="m-0 p-0 text-sm text-toolbar-text-secondary">{{ lessonDescription.length > 25 ? lessonDescription.slice(0,25) + '...' : lessonDescription }}</p>
            </span>
        </span>
        <DropdownMenu ref="tutorMenuRef" :open="tutorMenuOpen" @openChange="toggleTutorMenu" :modal="false" :update:open="toggleTutorMenu">
            <DropdownMenuTrigger>
                <span id="speed" @mouseover="onHover" @mouseleave="onLeave"
                @click="toggleTutorMenu"
                 class="mr-1 w-12 h-12 flex justify-center items-center hover:bg-toolbar-hover rounded-md cursor-pointer" :class="{ 'bg-toolbar-pressed': tutorMenuOpen }">
                    <PhGearSix class="w-6 h-6"/>
                </span>
            </DropdownMenuTrigger>
            <DropdownMenuContent v-on:interactOutside="toggleTutorMenu" v-on:escapeKeyDown="toggleTutorMenu" side="top" :sideOffset="12" class="w-48 p-[0.875rem] pb-[1.25rem] text-toolbar-text-primary bg-toolbar-pressed">
                <DropdownMenuItem class="m-0 p-0 mb-4 flex flex-col hover:bg-toolbar-pressed">
                    <p class="text-md-medium text-toolbar-text-primary">{{ pageStrings.speechSpeed }}</p>
                    <p class="text-xs text-toolbar-text-secondary">{{ speechSpeedRatio[0] }}x</p>
                    <Slider 
                        class="mt-2"
                        v-model="speechSpeedRatio"
                        :max="1.2"
                        :min="0.7"
                        :step="0.05"
                        :thumbValue="''"
                    /> 
                </DropdownMenuItem>
                <DropdownMenuItem class="m-0 p-0 mb-4 flex flex-col hover:bg-toolbar-pressed">
                    <p class="text-md-medium text-toolbar-text-primary">{{ pageStrings.tutorPatience }}</p>
                    <p class="text-xs text-toolbar-text-secondary">{{ pageStrings.tutorPatienceDescriptionShort }}</p>
                    <Slider 
                        class="mt-2"
                        v-model="tutorPatience"
                        :max="10"
                        :min="1"
                        :step="1"
                        :thumbValue="tutorPatience[0]"
                    /> 
                </DropdownMenuItem>
            <DropdownMenuItem class="m-0 p-0 mb-4 flex flex-col hover:bg-toolbar-pressed">
                <p class="text-md-medium text-toolbar-text-primary flex">{{ pageStrings.microphone }} 
                    <PhArrowClockwise @click="$emit('updateDevices', {})" class="ml-2 mt-[0.12rem] w-4 h-4 cursor-pointer" v-if="!props.updatingDevices"/>
                    <PhSpinner class="ml-2 mt-[0.12rem] w-4 h-4 animate-spin" v-if="props.updatingDevices"/> </p>
                <Select 
                  @update:model-value="$emit('updateDevices', { mic: $event })"
                  :model-value="(props.selectedDevice as any)?.mic?.deviceId"
                  :disabled="props.updatingDevices"
                >
                  <SelectTrigger class="w-full">
                    <SelectValue :placeholder="pageStrings.selectMicrophone" />
                  </SelectTrigger>
                  <SelectContent class="bg-toolbar-pressed text-toolbar-text-primary">
                    <SelectGroup>
                      <SelectItem
                        class="text-sm cursor-pointer"
                        v-for="device in props.availableDevices.filter(d => (d as any).kind === 'audioinput')"
                        :key="(device as any).deviceId"
                        :value="(device as any).deviceId"
                      >
                        {{ (device as any).label || `Microphone ${(device as any).deviceId}` }}
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
            </DropdownMenuItem>
            <DropdownMenuItem class="m-0 p-0 mb-4 flex flex-col hover:bg-toolbar-pressed">
                <p class="text-md-medium text-toolbar-text-primary flex">{{ pageStrings.speaker }}
                    <PhArrowClockwise @click="$emit('updateDevices', {})" class="ml-2 mt-[0.12rem] w-4 h-4 cursor-pointer" v-if="!props.updatingDevices"/>
                    <PhSpinner class="ml-2 mt-[0.12rem] w-4 h-4 animate-spin" v-if="props.updatingDevices"/></p>
                <Select 
                  @update:model-value="$emit('updateDevices', { speaker: $event })"
                  :model-value="(props.selectedDevice as any)?.speaker?.deviceId"
                  :disabled="props.updatingDevices"
                >
                  <SelectTrigger class="w-full">
                    <SelectValue class="text-sm" :placeholder="pageStrings.selectAudioOutput" /> 
                  </SelectTrigger>
                  <SelectContent class="bg-toolbar-pressed text-toolbar-text-primary">
                    <SelectGroup>
                      <SelectItem
                        class = "text-sm cursor-pointer"
                        v-for="device in props.availableDevices.filter(d => (d as any).kind === 'audiooutput')"
                        :key="(device as any).deviceId"
                        :value="(device as any).deviceId"
                      >
                        {{ (device as any).label || `Speaker ${(device as any).deviceId}` }}
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
            </DropdownMenuItem>
            <DropdownMenuItem class="m-0 p-0 flex flex-col hover:bg-toolbar-pressed">
            <ConfirmDialog :clickedContinue="clickedContinue" :pageStrings="props.pageStrings">
                <span id="settings" @mouseover="onHover" @mouseleave="onLeave" class="mr-1 flex justify-center items-center cursor-pointer">
                    <p>{{ pageStrings.seeAllSettings }}</p>
                </span>
            </ConfirmDialog>
            </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
        <span id="mute" @mouseover="onHover" @mouseleave="onLeave" @click="props.toggleMute" class="mr-1 w-12 h-12 flex justify-center items-center hover:bg-toolbar-hover rounded-md cursor-pointer">
            <PhMicrophoneSlash v-if="props.isMuted || !props.aiReady" class="w-6 h-6"/>
            <PhMicrophone v-else class="w-6 h-6"/>
        </span>

        <span @click="clickLeaveCall" class="mr-0.25 w-24 flex justify-center items-center hover:bg-toolbar-hover rounded-md cursor-pointer">
            <ToolbarSwitch id="leave" @mouseover="onHover" @mouseleave="onLeave" :checked="props.aiReady" @update:checked="clickLeaveCall" />
        </span>

    </span>
</template>