<script setup lang="ts">
import { PhChats, PhBookOpenText, PhPersonSimpleTaiChi, PhLightbulb, PhFilmSlate, PhCircleNotch  } from '@phosphor-icons/vue';
import { onMounted, ref, watch } from 'vue';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import type { UserSettings } from '@/types/model';
const isFocused = ref(false);

const props = defineProps<{
  pageStrings: { [key: string]: string },
  startEnabled: boolean,
  clickedStart: (name: string) => void,
  handleEnterKey: (event: KeyboardEvent, name: string) => void,
  goToEmail: () => void,
  user: UserSettings | null,
}>();

const nameInputField = ref("");

if (props.user) {
  if (props.user.name && nameInputField.value === "") {
    nameInputField.value = props.user.name;
  }
}
onMounted(() => {
  setTimeout(() => {  
    isFocused.value = true;
  }, 100);
});

watch(() => props.user, (newUser) => {
  if (newUser?.name && nameInputField.value === "") {
    nameInputField.value = newUser.name;
  }
  if (!newUser) {
    nameInputField.value = "";
  }
});
</script>

<style scoped>  
.bg-button-primary {
  background: var(--button-primary);
}
.bg-button-secondary {
  background: var(--button-secondary);
}
</style>

<template>  
    <span class="p-1 mb-8 flex flex-row flex-wrap justify-center items-center gap-4 shadow-elevation-toolbar rounded-lg w-full">

      <Input 
            ref="myinput"
            type="text" 
            class="w-[12rem] md:w-[16.5rem]"
            v-model="nameInputField" 
            autofocus
            :focus="isFocused"
            @focus="isFocused=true"
            @blur="isFocused = false"
            :placeholder="pageStrings.enterName"
            @keyup="handleEnterKey($event, nameInputField)"
          />
        <div class="bg-toolbar-background rounded-sm">
            <Button class="buttonDefault w-[7.5rem]" :disabled="!startEnabled" @click="clickedStart(nameInputField)">
                <PhCircleNotch v-if="!startEnabled" class="w-6 h-6 mr-1 animate-spin"/>
                <PhChats v-else class="w-6 h-6 mr-1"/>
                {{ pageStrings.startLesson }}
            </Button>
        </div>
        <p class="mt-2" v-if="(!user || user.userStatus === 'TRIAL') && startEnabled">
            {{ pageStrings.alreadyHave }} <span class="cursor-pointer text-text-interactive" @click="goToEmail">{{ pageStrings.logIn }}</span>
        </p>
    </span>
</template>