import type { KickstartMessage, Message } from '@/types/model';
import Daily from '@daily-co/daily-js';
import type { Ref } from 'vue';
import { PracticeAPI } from './api';


export class DailyCallManager {
    call: any;
    joinedDailyFailed: Ref<boolean>;
    currentRoomUrl: string | null;
    aiReady: Ref<boolean>;
    remoteParticipantId: string | null;
    tracks: any;  
    messages: Ref<Message[]>;
    audioId: string | null;
    sessionError: Ref<number | null>;
    dailyPoints: Ref<number>;
    userId: string;
    startedTrack: boolean;
    noiseCancellation: boolean;
    kickstartMessage: KickstartMessage | null;
    sentKickstartMessage: boolean;
    checkedCorrectDevice: boolean;
    streamingCb: (streamingObj: { type: string; message_id: string }) => void;
    vadCb: () => void;
    messageCommittedCb: () => void;
    aiLeftCb: () => void;
    onboardingFinishedCb: () => void;
    constructor(joinedDailyFailed: Ref<boolean>, aiReady: Ref<boolean>, messages: Ref<Message[]>, sessionError: Ref<number | null>, 
      dailyPoints: Ref<number>, noiseCancellation: boolean, streamingCb: (streamingObj: { type: string; message_id: string }) => void, 
      vadCb: () => void, onboardingFinishedCb: () => void, messageCommittedCb: () => void, aiLeftCb: () => void) {
      this.joinedDailyFailed = joinedDailyFailed;
      this.aiReady = aiReady;
      this.dailyPoints = dailyPoints;
      this.noiseCancellation = noiseCancellation;
      this.call = Daily.createCallObject();
      this.currentRoomUrl = null;
      this.messages = messages;
      this.audioId = null;
      this.userId = "";
      this.remoteParticipantId = null;
      this.tracks = null;
      this.sessionError = sessionError;
      this.startedTrack = false;
      this.kickstartMessage = null;
      this.sentKickstartMessage = false;
      this.checkedCorrectDevice = false;
      this.streamingCb = streamingCb;
      this.vadCb = vadCb;
      this.messageCommittedCb = messageCommittedCb;
      this.aiLeftCb = aiLeftCb;
      this.onboardingFinishedCb = onboardingFinishedCb;
      this.initialize();

    }
  
    /**
     * Performs initial setup of event listeners and UI component interactions.
     */
    async initialize() {
      this.setupEventListeners();
      this.call.startRemoteParticipantsAudioLevelObserver();
      this.call.updateInputSettings({
        audio: {
          processor: {
            type: this.noiseCancellation ? 'noise-cancellation' : 'none', 
          },
        },
      });
    }

    toggleMicrophone() {
      this.call.setLocalAudio(!this.call.localAudio());
    }
    toggleMicrophoneDiscard() {
      this.call.setLocalAudio(!this.call.localAudio(), {forceDiscardTrack: true});
    }
    setMic(value: boolean) {
      this.call.setLocalAudio(value);
    }
    getLocalAudio() {
      return this.call.localAudio();
    }

    getLocalAudioLevel() {
      return this.call.getLocalAudioLevel();
    }

    getRemoteParticipantAudioLevel() {
      return this.call.remoteParticipantAudioLevel();
    }

    setKickstartMessage(message: KickstartMessage) {
      this.kickstartMessage = message;
    }
    reloadUser() {
      this.sendAppMessage({type: "reload_user"});
    }
    /**
     * Configures event listeners for various call-related events.
     */
    setupEventListeners() {
      const events = {
        error: this.handleError.bind(this),
        'joined-meeting': this.handleJoin.bind(this),
        'participant-joined': this.handleParticipantJoinedOrUpdated.bind(this),
        'participant-updated': this.handleParticipantJoinedOrUpdated.bind(this),
        'app-message': this.handleAppMessage.bind(this),
      };
  
      Object.entries(events).forEach(([event, handler]) => {
        this.call.on(event, handler);
      });
    }
  
    /**
     * Handler for the local participant joining:
     * - Prints the room URL
     * - Enables the toggle camera, toggle mic, and leave buttons
     * - Gets the initial track states
     * - Sets up and enables the device selectors
     * @param {Object} event - The joined-meeting event object.
     */
    handleJoin(event: { participants: { local: { tracks: any; }; }; }) {
      // const tracks = event.participants.local.tracks;
      console.log(`Successfully joined: ${this.currentRoomUrl}`);

    }

    sendAppMessage(message: any) {
      this.call.sendAppMessage(message);
    }

  
    /**
     * Handles fatal errors emitted from the Daily call object.
     * These errors result in the participant leaving the meeting. A
     * `left-meeting` event will also be sent, so we still rely on that event
     * for cleanup.
     * @param {Object} e - The error event object.
     */
    handleError(e: any) {
      console.error('DAILY SENT AN ERROR!', e.error ? e.error : e.errorMsg);
    }
  
  
    /**
     * Handles participant-joined and participant-updated events:
     * - Updates the participant count
     * - Creates a video container for new participants
     * - Creates an audio element for new participants
     * - Manages video and audio tracks based on their current state
     * - Updates device states for the local participant
     * @param {Object} event - The participant-joined, participant-updated
     * event object.
     */
    async handleParticipantJoinedOrUpdated(event: any) {
      const { participant } = event;
      const participantId = participant.session_id;
      const isLocal = participant.local;
      const tracks = participant.tracks;
      if (!isLocal) {
        // Save the servers tracks and ID.
        this.remoteParticipantId = participantId;
        this.tracks = tracks;
      }
      if (event.action === "participant-joined") {
        this.aiReady.value = true;
        this.audioId = `audio-${participantId}`;
      }
      // Always update the participant count regardless of the event action
      if (this.audioId) {
        if (!document.getElementById(this.audioId)) {
          this.createAudioElement(this.audioId);
        }
        // Now play the servers track
        Object.entries(this.tracks).forEach(([trackType, trackInfo]) => {
          // @ts-ignore
          if (trackInfo.persistentTrack && this.remoteParticipantId) {
            if (true) {
              this.startedTrack = true;
              console.log("Starting track for ", this.remoteParticipantId);
              this.startOrUpdateTrack(trackType, trackInfo, this.remoteParticipantId);
              if (this.kickstartMessage && !this.sentKickstartMessage) {
                this.sendAppMessage(this.kickstartMessage);
                this.sentKickstartMessage = true;
              }
            }
          }
        });
      }
      if (!this.checkedCorrectDevice) {
        await this.checkCorrectDevice();
      }
    }

    async getDevices(): Promise<Object[]> {
      const devices = await this.call.enumerateDevices();
      
      // First pass to get unique devices by deviceId
      const uniqueDevices = devices.devices.reduce((acc: any[], device: any) => {
        if (!acc.find((d: any) => d.deviceId === device.deviceId) && 
            (device.kind === 'audioinput' || device.kind === 'audiooutput') &&
            !device.label.toLowerCase().includes('monitor')) {
          acc.push(device);
        }
        return acc;
      }, []);

      // Find audio inputs with duplicate labels
      const audioInputs = uniqueDevices.filter((device: any) => device.kind === 'audioinput');
      const labelCounts = audioInputs.reduce((acc: {[key: string]: number}, device: any) => {
        acc[device.label] = (acc[device.label] || 0) + 1;
        return acc;
      }, {});

      return uniqueDevices;
    }
    async getSelectedDevice(): Promise<Object> {
      const devices = await this.call.getInputDevices();
      return {mic: devices.mic, speaker: devices.speaker}; 
    }

    async checkCorrectDevice() {
      const devices = await this.call.enumerateDevices();
      const myDevice = await this.call.getInputDevices();
      if (myDevice && myDevice.mic && myDevice.mic.deviceId && devices && devices.devices && devices.devices.length > 0) {
        this.checkedCorrectDevice = true;
        const selectedDeviceId = myDevice.mic.deviceId;

        const device = devices.devices.find((device: any) => device.deviceId === selectedDeviceId);
        const myDeviceLabel = device.label;
        if (myDeviceLabel.toLowerCase().includes("iphone")) {
          const alternativeDevice = devices.devices.find((device: any) => (device.deviceId !== selectedDeviceId && device.kind === "audioinput"));
          if (alternativeDevice) {
            await this.call.setInputDevicesAsync({ audioDeviceId: alternativeDevice.deviceId });
          }
        }
      }
    }
    /**
     * Handles app messages received during the call, from the backend.
     * @param {Object} event - The app message event object.
     */
    handleAppMessage(event: any) {
      if (event.data.type === "error") {
        this.sessionError.value = event.data.error;
      } else if (event.data.type === "leave") {
        this.startedTrack = false;
        this.audioId = null;
        this.aiLeftCb();
      } else if (event.data.type === "daily_points") {
        this.dailyPoints.value = event.data.points;
      } else if (event.data.type === "vad") {
        this.vadCb();
      } else if (event.data.type === "onboarding_finished") {
        this.onboardingFinishedCb();
      } else if (event.data.type === "message_committed") {
        this.messageCommittedCb();
      } else if (event.data.type === "start_streaming") {
        this.streamingCb(event.data);
      } else if (event.data.type === "stop_streaming") {
        this.streamingCb(event.data);
      } else if (event.data.type === "message") {
        event.data.message.confirmed = event.data.confirmed;
        let found = false;
        for (const message of this.messages.value) {
          if (message.messageId === event.data.message.messageId) {
            message.content = event.data.message.content;
            message.confirmed = event.data.confirmed;
            found = true;
            break;
          }
        }
        if (!found) {
          this.messages.value.push(event.data.message);
        }
      }
    }
    async startAudio() {
      console.log("Starting audio");
      await this.call.setLocalAudio(true);
    }

    /**
     * Tries to join a call with provided room URL and optional join token.
     * @param {string} roomUrl - The URL of the room to join.
     * @param {string|null} joinToken - An optional token for joining the room.
     */
    async joinRoom(roomUrl: string, joinToken: string | null) {
      if (!roomUrl) {
        console.error('Room URL is required to join a room.');
        return;
      }

  
      this.currentRoomUrl = roomUrl;
      this.startedTrack = false;
      this.audioId = null;
      this.sentKickstartMessage = false;
      this.checkedCorrectDevice = false;

      const joinOptions = { url: roomUrl, startAudioOff: true, dailyConfig: {
        useDevicePreferenceCookies: true
      }};
      if (joinToken) {
        (joinOptions as { url: string; token?: string }).token = joinToken;
        console.log('Joining with a token.');
      } else {
        console.log('Joining without a token.');
      }
  
      try {
        const participants = await this.call.join(joinOptions);
        this.userId = participants.local.user_id;
      } catch (e) {
        console.error('Join failed:', e);
      }
    }
  
    async leaveRoom() {
      await this.call.leave();
      this.checkedCorrectDevice = false;
    }

    async destroy() {
      await this.call.destroy();
    }
  
    /**
     * Creates an audio element for a particular participant. This function is
     * responsible for dynamically generating a standalone audio element that can
     * be used to play audio streams associated with the specified participant.
     * The audio element is appended directly to the document body or a relevant
     * container, thereby preparing it for playback of the participant's audio.
     *
     * @param {string} participantId - A unique identifier corresponding to the participant.
     */
    createAudioElement(audioId: string) {
      const audioEl = document.createElement('audio');
      audioEl.id = audioId;
      document.body.appendChild(audioEl);
    }
  
    /**
     * Updates the media track (audio or video) source for a specific participant
     * and plays the updated track. It checks if the source track needs to be
     * updated and performs the update if necessary, ensuring playback of the
     * media track.
     *
     * @param {string} trackType - Specifies the type of track to update ('audio'
     * or 'video'), allowing the function to dynamically adapt to the track being
     * processed.
     * @param {Object} track - Contains the media track data, including the
     * `persistentTrack` property which holds the actual MediaStreamTrack to be
     * played or updated.
     * @param {string} participantId - Identifies the participant whose media
     * track is being updated.
     */
    startOrUpdateTrack(trackType: string, track: any, participantId: string) {
      const selector =
        trackType === 'video'
          ? `#video-container-${participantId} video.video-element`
          : `audio-${participantId}`;
  
      // Retrieve the specific media element from the DOM.
      const trackEl: HTMLAudioElement | null = document.getElementById(selector) as HTMLAudioElement;
  
      // Error handling if the target media element does not exist.
      if (!trackEl) {
        console.error(
          `${trackType} element does not exist for participant: ${participantId}`
        );
        return;
      }
  
      // Check for the need to update the media source. This is determined by
      // checking whether the existing srcObject's tracks include the new
      // persistentTrack. If there are no existing tracks or the new track is not
      // among them, an update is necessary.
      const existingTracks = trackEl.srcObject instanceof MediaStream ? trackEl.srcObject.getTracks() : [];
      const needsUpdate = !existingTracks.includes(track.persistentTrack);
  
      // Perform the media source update if needed by setting the srcObject of
      // the target element to a new MediaStream containing the provided
      // persistentTrack.
      if (needsUpdate) {
        trackEl.srcObject = new MediaStream([track.persistentTrack]);
  
        // Once the media metadata is loaded, attempts to play the track. Error
        // handling for play failures is included to catch and log issues such as
        // autoplay policies blocking playback.
        trackEl.onloadedmetadata = () => {
          trackEl
            .play()
            .catch((e) =>{
              this.joinedDailyFailed.value = true;
              console.error(
                `Error playing ${trackType} for participant ${participantId}:`,
                e
              )
            }
            );
        };
      }
    }
}
