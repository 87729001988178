import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import type { Updater } from '@tanstack/vue-table'
import type { Ref } from 'vue'
import { LocaleToLanguage } from '@/languages/messages'

export const getDefaultSourceLanguage = (): string => {
  const browserLang = navigator.language;
  const sourceLocale = browserLang.split('-')[0];
  const sourceLangId = LocaleToLanguage[sourceLocale];

  if (sourceLangId && Object.values(Language).includes(sourceLangId as Language)) {
    return sourceLangId;
  }
  
  return Language.ENGLISH;
}


export function cleanString(input: string): string {
    return input
        .toLowerCase()                          // Convert to lowercase
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')  // Remove punctuation
        .replace(/\s+/g, '');                   // Remove all spaces
}

export async function createAudioBuffer(
    context: BaseAudioContext,
    float32Array: Float32Array,
    channels: number
): Promise<AudioBuffer> {
    const audioBuffer = context.createBuffer(channels, float32Array.length / channels, context.sampleRate);

    for (let channel = 0; channel < channels; channel++) {
        const channelData = audioBuffer.getChannelData(channel);
        for (let i = 0; i < channelData.length; i++) {
            channelData[i] = float32Array[i * channels + channel];
        }
    }

    return audioBuffer;
}

export function audioBufferToFloat32Array(audioBuffer: AudioBuffer): Float32Array {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length;

    // Initialize a Float32Array to hold the interleaved data
    const interleaved = new Float32Array(length * numberOfChannels);

    // Interleave the channel data
    for (let channel = 0; channel < numberOfChannels; channel++) {
        const channelData = audioBuffer.getChannelData(channel);
        for (let i = 0; i < channelData.length; i++) {
            interleaved[i * numberOfChannels + channel] = channelData[i];
        }
    }

    return interleaved;
}

export async function resampleAudioBuffer(
    audioBuffer: AudioBuffer,
    targetSampleRate: number
): Promise<AudioBuffer> {
    const offlineContext = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        audioBuffer.duration * targetSampleRate,
        targetSampleRate
    );

    const bufferSource = offlineContext.createBufferSource();
    bufferSource.buffer = audioBuffer;
    bufferSource.connect(offlineContext.destination);
    bufferSource.start(0);

    return await offlineContext.startRendering();
}

export async function audioToWebSocketData(audioContext: AudioContext, float32Data: Float32Array, sampleRate: number): Promise<ArrayBuffer> {
    const audioBuffer = await createAudioBuffer(audioContext, float32Data, 1);
    const resampledBuffer = await resampleAudioBuffer(audioBuffer, sampleRate);
    const resampledData = audioBufferToFloat32Array(resampledBuffer);
    return float32ToByteArray(resampledData);
}

export function float32ToByteArray(float32Array: Float32Array): Uint8Array {
    // Create an ArrayBuffer with the same byte length as the float32Array
    const buffer = new ArrayBuffer(float32Array.length * 4); // 4 bytes per float32
    // Create a DataView to manipulate the ArrayBuffer
    const view = new DataView(buffer);

    // Store each float32 value as bytes
    for (let i = 0; i < float32Array.length; i++) {
        view.setFloat32(i * 4, float32Array[i], true); // true for little-endian
    }

    return new Uint8Array(buffer);
}

export const createAudioLink = (word: string, downloadBlob: Blob) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(downloadBlob);
  link.download = word + ".mp3";
  link.click();
  link.remove(); // Clean up the DOM
}

export const getErrorMessage = (event: any) => {
  if (event instanceof Event) {
    return 'A WebSocket error occurred.';
  } else if (event instanceof ErrorEvent) {
    return `Error message: ${event.message}`;
  } else if (typeof event === 'string') {
    return event;
  } else {
    return 'An unknown WebSocket error occurred.';
  }
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function valueUpdater<T extends Updater<any>>(updaterOrValue: T, ref: Ref) {
  ref.value = typeof updaterOrValue === 'function'
    ? updaterOrValue(ref.value)
    : updaterOrValue
}

export enum Language {
    AFRIKAANS = "afrikaans",
    ARABIC = "arabic",
    ARMENIAN = "armenian",
    AZERBAIJANI = "azerbaijani",
    BELARUSIAN = "belarusian",
    BOSNIAN = "bosnian",
    BULGARIAN = "bulgarian",
    CATALAN = "catalan",
    MANDARIN_CHINESE = "mandarin_chinese",
    CANTONESE = "cantonese",
    CROATIAN = "croatian",
    CZECH = "czech",
    DANISH = "danish",
    DUTCH = "dutch",
    ENGLISH = "english",
    ESTONIAN = "estonian",
    FINNISH = "finnish",
    FRENCH = "french",
    GALICIAN = "galician",
    GERMAN = "german",
    GREEK = "greek",
    GUJARATI = "gujarati",
    HEBREW = "hebrew",
    HINDI = "hindi",
    HUNGARIAN = "hungarian",
    ICELANDIC = "icelandic",
    INDONESIAN = "indonesian",
    ITALIAN = "italian",
    JAPANESE = "japanese",
    KANNADA = "kannada",
    KAZAKH = "kazakh",
    KOREAN = "korean",
    LATVIAN = "latvian",
    LITHUANIAN = "lithuanian",
    MACEDONIAN = "macedonian",
    MALAY = "malay",
    MARATHI = "marathi",
    MAORI = "maori",
    NEPALI = "nepali",
    NORWEGIAN = "norwegian",
    PERSIAN = "persian",
    POLISH = "polish",
    PORTUGUESE = "portuguese",
    ROMANIAN = "romanian",
    RUSSIAN = "russian",
    SERBIAN = "serbian",
    SLOVAK = "slovak",
    SLOVENIAN = "slovenian",
    SPANISH = "spanish",
    SWAHILI = "swahili",
    SWEDISH = "swedish",
    TAGALOG = "tagalog",
    TAMIL = "tamil",
    THAI = "thai",
    TURKISH = "turkish",
    UKRAINIAN = "ukrainian",
    URDU = "urdu",
    VIETNAMESE = "vietnamese",
    WELSH = "welsh"
}

export const LanguageToFlag: { [key: string]: string } = {
  "afrikaans": "za",
  "arabic": "sa",
  "armenian": "am",
  "azerbaijani": "az",
  "belarusian": "by",
  "bosnian": "ba",
  "bulgarian": "bg",
  "catalan": "es",
  "cantonese": "hk",
  "mandarin_chinese": "cn",
  "croatian": "hr",
  "czech": "cz",
  "danish": "dk",
  "dutch": "nl",
  "english": "us",
  "estonian": "ee",
  "finnish": "fi",
  "french": "fr",
  "galician": "es",
  "german": "de",
  "greek": "gr",
  "gujarati": "in",
  "hebrew": "il",
  "hindi": "in",
  "hungarian": "hu",
  "icelandic": "is",
  "indonesian": "id",
  "italian": "it",
  "japanese": "jp",
  "kannada": "in",
  "kazakh": "kz",
  "korean": "kr",
  "latvian": "lv",
  "lithuanian": "lt",
  "macedonian": "mk",
  "malay": "my",
  "marathi": "in",
  "maori": "nz",
  "nepali": "np",
  "norwegian": "no",
  "persian": "ir",
  "polish": "pl",
  "portuguese": "pt",
  "romanian": "ro",
  "russian": "ru",
  "serbian": "rs",
  "slovak": "sk",
  "slovenian": "si",
  "spanish": "es",
  "swahili": "tz",
  "swedish": "se",
  "tagalog": "ph",
  "tamil": "in",
  "thai": "th",
  "turkish": "tr",
  "ukrainian": "ua",
  "urdu": "pk",
  "vietnamese": "vn",
  "welsh": "gb"
};

export function getLanguageFlagLipis(language: string): string {
  // Using https://flagicons.lipis.dev/
  const lowercaseLanguage = language.toLowerCase();
  return LanguageToFlag[lowercaseLanguage] || 'un'; // Default is UN!
}


export function getLanguageFlag(language: string): string {
  console.log("language: " + language);
  // Using Figma SVG icons
  const lowercaseLanguage = language.toLowerCase();
  const uppercaseLanguage = LanguageToFlag[lowercaseLanguage];
  if (!uppercaseLanguage) {
    return 'earth'; // Default is a nice earth icon!
  }
  return uppercaseLanguage.toUpperCase();
}
