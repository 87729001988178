<script setup lang="ts">
import type { SliderRootEmits, SliderRootProps } from 'radix-vue'
import { cn } from '@/practice/util'
import { SliderRange, SliderRoot, SliderThumb, SliderTrack, useForwardPropsEmits } from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'

const props = defineProps<SliderRootProps & {
  class?: HTMLAttributes['class']
  thumbValue?: string | number
  thumbColor?: string
  trackColor?: string
}>()
const emits = defineEmits<SliderRootEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <SliderRoot
    :class="cn(
      'relative flex w-full touch-none select-none items-center data-[orientation=vertical]:flex-col data-[orientation=vertical]:w-1.5 data-[orientation=vertical]:h-full',
      props.class,
    )"
    v-bind="forwarded"
  >
    <SliderTrack
      :class="cn(
        'relative h-2 w-full data-[orientation=vertical]:w-1.5 grow overflow-hidden rounded-full',
        trackColor || 'bg-toolbar-control'
      )"
    >
      <SliderRange class="absolute h-full data-[orientation=vertical]:w-full bg-bg-active" />
    </SliderTrack>
    <SliderThumb
      v-for="(_, key) in modelValue"
      :key="key"
      :class="cn(
        'block h-5 w-5 text-sm-medium flex items-center justify-center rounded-full border-0.5 border-primary ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        thumbColor || 'bg-toolbar-background'
      )"
    >
      {{ props.thumbValue }}
    </SliderThumb>
  </SliderRoot>
</template>
