import { watch } from "vue";

export async function waitForRef(refValue: any, targetValue: any): Promise<void> {
  return new Promise((resolve) => {
    let unwatch: () => void;  // Declare the variable first
    
    // Immediately check if the ref already equals target value
    if (refValue.value === targetValue) {
      resolve();
      return;
    }

    // Then assign the watch function to unwatch
    unwatch = watch(
      refValue,
      (newValue) => {
        if (newValue === targetValue) {
          unwatch(); // Now we can safely use unwatch
          resolve();
        }
      }
    );
  });
}

export async function waitForEither(refA: any, valueA: any, refB: any, valueB: any): Promise<void> {
  return new Promise((resolve) => {
    let unwatchA: () => void;
    let unwatchB: () => void;
    
    // Check initial values
    if (refA.value === valueA || refB.value === valueB) {
      resolve();
      return;
    }

    // Watch first ref
    unwatchA = watch(
      refA,
      (newValue) => {
        if (newValue === valueA) {
          unwatchA();
          unwatchB();
          resolve();
        }
      }
    );

    // Watch second ref
    unwatchB = watch(
      refB,
      (newValue) => {
        if (newValue === valueB) {
          unwatchA();
          unwatchB();
          resolve();
        }
      }
    );
  });
}