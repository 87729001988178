import { LocaleToLanguage } from "../messages";
import serverErrorStrings from "@/../../shared/errors.json";


export function getErrorMessagesFromLanguage(language: string): { [key: number]: string } {
    const errors: { [key: number]: string } = {};
    
    // Get translations for requested language, falling back to English
    const translations = (serverErrorStrings as any)[language] || (serverErrorStrings as any)["english"];
    
    // Convert each error code and message into the errors map
    for (const [errorCode, message] of Object.entries(translations)) {
        errors[Number(errorCode)] = message as string;
    }
    
    return errors;
}
  
export function getErrorMessagesFromLocale(locale: string): { [key: number]: string } {
    return getErrorMessagesFromLanguage(LocaleToLanguage[locale]);
}