<script setup lang="ts">
import { useRouter } from 'vue-router';
import SettingsDropdown from "@/practice/SettingsDropdown.vue";
import type { GetUserResponse } from '@/practice/api';
import type { Ref } from 'vue';

const props = defineProps<{
  pageStrings: { [key: string]: string },
  getUserResult: {
    data: Ref<GetUserResponse | null>;
    isFetching: Ref<boolean>;
    error: Ref<number | null>;
  };
  preLogout: () => void;
  postLogout?: () => void;
}>();

const router = useRouter();

function goToHome() {
  router.push("/");
}
</script>

<template>
  <div class="w-full flex justify-between px-4 pt-4 md:px-10 md:pt-10 mb-4">
      <img src="/assets/logo-black.png" alt="issen logo" class="mt-2 ml-2 h-7 md:h-10 cursor-pointer" @click="goToHome">
      <SettingsDropdown :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="preLogout" :postLogout="postLogout" />
  </div>
</template>


<style scoped>
/* Component styles go here */
</style>

