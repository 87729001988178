<script setup lang="ts">
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import IssenHeader from "@/practice/IssenHeader.vue";
import { onMounted, ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { apiRes, PracticeAPI, type GetUserResponse } from '@/practice/api';
import { domainPort, protocol } from '@/url';

const sourceLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(sourceLanguage.value));

const getUserResult = apiRes<GetUserResponse>();
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);

onMounted(async () => {
  await API.getUser(getUserResult);
  if (getUserResult.data.value && getUserResult.data.value.user.sourceLang) {
    sourceLanguage.value = getUserResult.data.value.user.sourceLang;
    pageStrings.value = getMessagesFromLanguage(sourceLanguage.value);
  }
})



</script>

<template>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="()=>{}" :postLogout="()=>{}" />
    </div>
    <div class="flex-1 flex flex-col w-full">
      <div class="flex flex-grow justify-center items-center">
        <Card class="w-[350px] bg-bg-base shadow-card-rest">
          <CardHeader>
            <CardTitle class="text-xl">{{ pageStrings.maxUsage}}</CardTitle>
          </CardHeader>
          <CardContent>
            <p class="mt-4">{{ pageStrings.maxUsageExplanation }}</p>
          </CardContent>
          <CardFooter class="flex justify-between px-6 pb-6">
          </CardFooter>
        </Card>
      </div>
    </div>
  </span>
</template>
