<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { getLanguageFlag } from "@/practice/util";
import { PhFireSimple } from '@phosphor-icons/vue';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

const props = defineProps<{
  pageStrings: { [key: string]: string },
  dailyPoints: number;
  dailyGoal: number;
  language: string;
  userStreak: number;
}>();

const bounce = ref(false);
const dailyProgress = computed(() => {
  return Math.min(100, Math.floor(props.dailyPoints / (props.dailyGoal / 100.1)));
});
const tmpStreakOffset = ref(0);

watch(() => props.userStreak, (newValue: number, oldValue: number) => {
  tmpStreakOffset.value = 0;
});

watch(dailyProgress, (newValue: number, oldValue: number) => {
  if (oldValue > 0 && newValue >= 100) {
    bounce.value = true;
    tmpStreakOffset.value += 1;
    setTimeout(() => {
      bounce.value = false;
    }, 3000);
  }
});

const streakFireClass = computed(() => {
  return props.userStreak + tmpStreakOffset.value === 0 ? 'streak-fire-empty' : (dailyProgress.value < 100 ? 'streak-fire-mid' : 'streak-fire-full');
});


</script>
<style scoped>
.streak-bg {
  background-color: #FF9F56;
}
.streak-fire-full {
  fill: #FF9F56;
  color: #FF9F56;
} 
.streak-fire-mid{
  fill: rgba(255, 159, 86, 0.7);
  color: rgba(255, 159, 86, 0.7);
}
.streak-fire-empty {
  fill: var(--bg-switch-off-hover);
  color: var(--bg-switch-off-hover);
}
.knewave {
  font-family: 'Knewave', cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

</style>

<template>  
    <div class="absolute left-1/2 transform -translate-x-1/2 top-[5rem] md:top-[2.75rem]">
      <div class="text-center pl-[0.625rem] pr-[1rem] rounded-xl w-[18.8125] h-[4rem] cursor-pointer w-64 flex items-center bg-bg-base shadow-modal z-50" :class="{ 'animate-bounce': bounce }">
        <TooltipProvider :delayDuration=500>
          <Tooltip>
            <TooltipTrigger asChild>
              <div class="relative mr-[0.625rem]">
                <PhFireSimple class="h-[2.75rem] w-[2.75rem]" :class="streakFireClass" />
                <span class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold knewave pt-1" :class="streakFireClass">{{ userStreak + tmpStreakOffset }}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent class="text-lg bg-toolbar-background tooltip-shadow text-fg-on-inverted" :hide-when-detached="true" >
              <p>{{ pageStrings.thisIsYourStreak }}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider :delayDuration=50>
          <Tooltip>
            <TooltipTrigger asChild>
              <div class="flex flex-1 flex-col items-center">
                <div class="flex justify-between w-full mb-[0.375rem]" v-if="dailyProgress < 100">
                  <p class="text-text-base text-sm-semi">{{ pageStrings.dailyGoal }}</p>
                  <p class="text-text-subtle text-sm-medium">{{ dailyPoints}} / {{ dailyGoal }} {{ pageStrings.min }}</p>
                </div>
                <div class="flex justify-between w-full mb-[0.375rem]" v-else>
                  <p class="text-text-base text-sm-semi">{{ pageStrings.hitGoal }}</p>
                  <p class="text-text-subtle text-sm-medium">{{ dailyPoints}} {{ pageStrings.min }}</p>
                </div>
                <div  class="w-full h-[0.5rem] rounded-sm bg-bg-disabled relative z-300">
                  <div v-if="dailyProgress < 100" class="absolute left-0 top-0 h-[0.5rem] rounded-sm bg-bg-active z-300" :style="{ width: dailyProgress + '%' }"></div>
                  <div v-else class="absolute left-0 top-0 w-[100%] h-[0.5rem] rounded-sm bg-bg-active z-300 streak-bg"></div>
                </div>
              </div>
            </TooltipTrigger>
            <TooltipContent class="text-lg bg-toolbar-background tooltip-shadow text-fg-on-inverted" :hide-when-detached="true" >
              <p>{{ pageStrings.clickToSeeLeaderboard }}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
</template>
