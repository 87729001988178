<script setup lang="ts">
import router from "@/router";

import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Input } from '@/components/ui/input';
import IssenHeader from "@/practice/IssenHeader.vue";
import { ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type CheckAllowlistResponse, type CheckUsernameResponse, type CreateUserResponse, type GetUserResponse, type LoginResponse, type LogoutResponse, PracticeAPI, type SubscribeResponse, type UpdateUserResponse } from "@/practice/api";
import { protocol, domainPort } from "@/url";
import { UserStatus } from "@/types/model";


const sourceLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(sourceLanguage.value));
const email = ref("");
const isFocused: Ref<boolean> = ref(false);
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const getUserResult = apiRes<GetUserResponse>();
const checkUsernameResult = apiRes<CheckUsernameResponse>();
const checkAllowlistResult = apiRes<CheckAllowlistResponse>();
const subscribeResult = apiRes<SubscribeResponse>();
const logoutResult = apiRes<LogoutResponse>();
import { priceId } from "@/practice/subscription";
const timelimit = ref(false);
import { useRoute } from "vue-router";
const loginResult = apiRes<LoginResponse>();
const updateUserResult = apiRes<UpdateUserResponse>();
const route = useRoute();
let priceIdOverride: string = priceId;
const buttonDisabled = ref(false);
const emailError = ref(false);


// 1. TRIAL, email exists, ?subscribe, allowlisted                    X
// 2. TRIAL, email exists, ?subscribe, not allowlisted                X
// 3. TRIAL, email exists, no ?subscribe, allowlisted                 X
// 4. TRIAL, email exists, no ?subscribe, not allowlisted             X
// 5. TRIAL, no email, ?subscribe, allowlisted                        X  
// 6. TRIAL, no email, ?subscribe, not allowlisted                    X     
// 7. TRIAL, no email, no ?subscribe, allowlisted                     X
// 8. TRIAL, no email, no ?subscribe, not allowlisted                 X
// 9-16. STANDARD:  Route to /chat                                    X
// 17-24. SUBSCRIBED, Route to /chat                                  X
// 25-32: not logged -> Create TRIAL account (1-8)                    X
// 33 Logging in to an existing account. (allowlisted by definition)  X

onMounted(async () => {
  buttonDisabled.value = false;
  if (route.query.timelimit) { 
    timelimit.value = true;
  }
  if (route.query.priceId) {
    priceIdOverride = route.query.priceId as string;
  }
  setTimeout(() => {  
    isFocused.value = true;
  }, 100);
  await API.getUser(getUserResult);
  if (getUserResult.data.value && getUserResult.data.value.user.sourceLang) {
    sourceLanguage.value = getUserResult.data.value.user.sourceLang;
    pageStrings.value = getMessagesFromLanguage(sourceLanguage.value);
  }
  if (getUserResult.data.value) {
    if (getUserResult.data.value.user.userStatus !== UserStatus.TRIAL) {
      // 9-24 -> {}
      router.push("/chat");
    }
  }

})

async function subscribe() {
  await API.subscribe(priceIdOverride, subscribeResult);
    if (subscribeResult.data.value) {
      window.location.href = subscribeResult.data.value.url;
    } else {
      console.log("No url in subscribeResult");
    }
}

async function advance(allowlisted: boolean, goToLogin: boolean) {
    let query_params: { email: string; subscribe?: string, priceId?: string, redirect?: string } = { email: email.value };
    if (route.query.subscribe) {
      query_params.subscribe = route.query.subscribe as string;
    }
    if (route.query.redirect) {
      query_params.redirect = route.query.redirect as string;
    }
    if (goToLogin) {
      // 33
      router.push({path: "/login", query: query_params});
    } else if (route.query.subscribe) {
      // 1,2
      await subscribe();
      return;
    } else if (allowlisted) {
      // 3
      router.push({path: "/password", query: query_params});
    } else {
      // 4
      router.push({path: "/subscribe"});
    }
}

async function continueWithEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.value)) {
      emailError.value = true;
      return;
    }
    buttonDisabled.value = true;
    await API.checkAllowlist(email.value, checkAllowlistResult);
    await API.checkUsername(email.value, checkUsernameResult);
    const usernameExists: boolean = checkUsernameResult.data.value?.usernameExists ?? false;
    const allowlisted: boolean = checkAllowlistResult.data.value?.allowlisted ?? false;

    if (!getUserResult.data.value) {
      // 25-32 -> 1-8
        const createUserResult = apiRes<CreateUserResponse>();
        await API.create_user(email.value, "password", createUserResult);
        await API.login(email.value, "password", loginResult);
        await API.update_user(updateUserResult, {name: email.value});
    }
    // From here on out, we have a TRIAL account so it's 1-8 or 26
    if (usernameExists) {
      await API.logout(logoutResult);
      await API.login(email.value, "password", loginResult);
      if (!loginResult.data.value || !loginResult.data.value.success) {
        // 33
        await advance(allowlisted, true);
        return;
      }
    }

    // We now have a TRIAL account and are logged in, and we know the email is ours
    if (!usernameExists) {
      // 5-8 -> 1-4
      await API.update_user(updateUserResult, {username: email.value});
    }

    // We now have a TRIAL account with the email on this account.
    await advance(allowlisted, false);
}
</script>

<template>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="() => {}" :postLogout="() => {}"/>
    </div>
    <div class="flex-1 flex flex-col w-full">
    <div class="flex flex-grow justify-center items-center">
      <Card class="w-[350px] bg-bg-base shadow-card-rest">
        <CardHeader>
          <CardTitle class="text-2xl" v-if="!timelimit">{{ pageStrings.continueWithEmail }}</CardTitle>
          <CardTitle class="text-2xl" v-if="timelimit">{{ pageStrings.timelimit }}</CardTitle>
          <CardDescription class="text-md" v-if="!timelimit">{{ pageStrings.logInToStartYourJourney }}</CardDescription>
          <CardDescription class="text-md" v-if="timelimit">{{pageStrings.timelimitExplanation }}</CardDescription>
        </CardHeader>
        <CardContent>
            <div class="grid items-center w-full gap-4">
              <div class="flex flex-col space-y-1.5">
                <Input v-model="email" id="email" placeholder="taylor@example.com"
                autofocus
                :focus="isFocused"
                @focus="isFocused = true" @blur="isFocused = false" 
                @keyup.enter="continueWithEmail" />
              </div>
          </div>
        </CardContent>
        <CardContent v-if="emailError">
            <p class="text-md text-red-400 font-bold">{{ pageStrings.invalidEmail }}</p>
        </CardContent>
        <CardFooter class="flex justify-between px-6 pb-6">
          <Button @click="continueWithEmail" class="buttonDefault" :disabled="buttonDisabled">
              {{ pageStrings.continue }}
          </Button>
        </CardFooter>
      </Card>
      </div>
    </div>
  </span>


</template>
