<script setup lang="ts">
import router from "@/router";

import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Input } from "@/components/ui/input";
import IssenHeader from "@/practice/IssenHeader.vue";
import { Label } from "@/components/ui/label"
import { ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type DeleteUserResponse, type GetUserResponse, type LogoutResponse, PracticeAPI } from "@/practice/api";
import { protocol, domainPort } from "@/url";
import { useRoute } from 'vue-router';
import { sha256 } from "@/practice/crypto";
import { getErrorMessagesFromLanguage, getErrorMessagesFromLocale } from "@/languages/errors/serverErrors";
import { CodeSquare } from "lucide-vue-next";


const sourceLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(sourceLanguage.value));
const password1 = ref("");
const password2 = ref("");
const name = ref(""); 

const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const getUserResult = apiRes<GetUserResponse>();
const error: Ref<string> = ref("");
const route = useRoute();
const email = ref("");
const deleteUserResult = apiRes<DeleteUserResponse>();
const logoutResult = apiRes<LogoutResponse>();

onMounted(async () => {
    await API.getUser(getUserResult);
    if (getUserResult.data.value && getUserResult.data.value.user.sourceLang) {
      sourceLanguage.value = getUserResult.data.value.user.sourceLang;
      pageStrings.value = getMessagesFromLanguage(sourceLanguage.value);
    }
    email.value = route.query.email as string || "";
    if (!getUserResult.data.value || getUserResult.data.value.user.userStatus === "TRIAL") {
      if (getUserResult.data.value) {
        await API.logout(logoutResult);
      }
      router.push("/email?redirect=delete-account");
      return;
    }
  }
)

async function goBack() {
  router.push("/settings");
}

async function deleteAccount() {
  // The user is changing their password
  if (password1.value === "password" || password1.value === "") {
    error.value = pageStrings.value.invalidPassword;
    return;
  }
  const hashedPass = await sha256(password1.value);
  console.log("hashedPass", hashedPass);
  await API.deleteUser(hashedPass, deleteUserResult);
  if (deleteUserResult.error.value) {
    console.log("deleteUserResult.error.value", deleteUserResult.error.value, sourceLanguage.value);
    error.value = getErrorMessagesFromLanguage(sourceLanguage.value)[deleteUserResult.error.value];
    console.log("error strings", getErrorMessagesFromLanguage(sourceLanguage.value));
    console.log("error", error.value);
    return;
  }
  router.push("/account-deleted");
}

</script>

<template>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="() => {}" :postLogout="() => {}"/>
    </div>
    <div class="flex-1 flex flex-col w-full">
      <div class="flex flex-grow justify-center items-center">
        <Card class="w-[350px] bg-bg-base shadow-card-rest">
          <CardHeader class="mb-0">
            <CardTitle class="text-xl mb-0">
              {{ pageStrings.deleteAccount }}</CardTitle>
          </CardHeader>
          <CardContent>
            <div v-if="error" class="text-red-500 font-bold mb-2 mt-0">{{ error }}</div>
            <p class="text-md">{{ pageStrings.areYouSureDeleteAccount1 }}</p>
            <p class="text-md mt-4">{{ pageStrings.areYouSureDeleteAccount2 }}</p>
            <div class="grid items-center w-full gap-4 mt-4">
              <div class="flex flex-col space-y-1.5">
                <Input type="password" v-model="password1" :placeholder="pageStrings.password" />
              </div>
            </div>
          </CardContent>
          <CardFooter class="flex px-6 pb-6">
            <Button @click="deleteAccount" class="buttonDefault">
              {{ pageStrings.deleteAccount }}
            </Button>
            <Button variant="secondary" @click="goBack" class="ml-2">
              {{ pageStrings.back }}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  </span>
</template>
